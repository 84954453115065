import React from "react";
import Footer from "./../Common/Footer";
import Header from "./../Common/Header";
import { connect } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { basicAPI } from "../data/articles.js";
var icon_upload = require("./../../images/icon_upload.png");
var icon_close = require("./../../images/icon_close.png");

const LANG = {
  JOIN_OUR_TEAM: {
    vi: "THAM GIA CÙNG CHÚNG TÔI",
    en: "JOIN OUR TEAM",
  },
  DETAIL: {
    vi: "&emsp;Tại The Five & Partnes, chúng tôi luôn chào đón các kiến trúc sư năng động và có năng lực từ khắp nơi trên thế giới. Các sản phẩm tư vấn của công ty chúng tôi mang tính tiên phong và khác biệt dựa trên quy trình nghiên cứu và sáng tạo phù hợp với xu hướng thiết kế của thời đại và sự phát triển của khoa học công nghệ.<br/><br />&emsp;Các dự án chúng tôi tham gia vô cùng đa dạng trong thể loại như dự án quy hoạch, chung cư cao tầng, tòa nhà văn phòng, công trình thương mại đến các dự án văn hóa đầy tính trải nghiệm kiến trúc.<br /><br />&emsp;Trụ sở văn phòng đặt tại thành phố Hồ Chí Minh, là thành phố năng động bậc nhất mang lại nhiều cơ hội phát triển cho các kiến trúc sư.",
    en: "&emsp;At The Five & Partners, we are excited to welcome proficient and dynamic architects from all corners of the world. Our consultancy services are groundbreaking and distinct, incorporating research and creative processes in line with current design trends and advancements in science and technology.<br /><br />&emsp;Our projects are diverse and include urban planning, high-rise residential complexes, office and commercial buildings, as well as cultural projects that provide exceptional architectural experiences.<br /><br />&emsp;Our office is located in Ho Chi Minh City, a dynamic metropolis that offers ample opportunities for growth and development in the architecture field.",
  },
  INFORMATION: {
    vi: "THÔNG BÁO",
    en: "INFORMATION",
  },
  ATTACHMENTS: {
    vi: "Tệp đính kèm:",
    en: "Attachments:",
  },

  APPLICATION: {
    vi: "ĐĂNG KÝ",
    en: "APPLICATION",
  },
  SUBMIT: {
    vi: "GỬI THÔNG TIN",
    en: "SUBMIT",
  },
  SENDING: {
    vi: "ĐANG GỬI...",
    en: "SENDING...",
  },
  FULLNAME: {
    vi: "Họ và tên:",
    en: "Fullname:",
  },
  place_fullname: {
    vi: "họ và tên",
    en: "your fullname",
  },
  POSITION: {
    vi: "Vị trí:",
    en: "Position:",
  },
  place_position: {
    vi: "vị trí",
    en: "your position",
  },
  place_email: {
    vi: "email",
    en: "email",
  },
  PHONE: {
    vi: "Số điện thoại:",
    en: "Phone:",
  },
  place_phone: {
    vi: "số điện thoại",
    en: "your phone",
  },
  QUESTION: {
    vi: "Bạn muốn chúng tôi biết gì về bạn?",
    en: "What would you like us to know about you?",
  },
  place_question: {
    vi: "hãy để lại lời nhắn",
    en: "leave a message",
  },
  MORE_ABOUT_THEFIVE: {
    vi: "Khám phá thêm THE FIVE về",
    en: "More about THE FIVE's",
  },
  COMPANY: {
    vi: "công ty",
    en: "company",
  },
  PEOPLE: {
    vi: "con người",
    en: "people",
  },
  PROJECTS: {
    vi: "dự án",
    en: "projects",
  },
  AND: {
    vi: ", và",
    en: ", and",
  },
};

class Awards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cvFile: null,
      cvFileName: "",
      fullname: "",
      position: "",
      email: "",
      phone: "",
      message: "",
      showToast: false,
      message_en: "",
      message_vi: "",
      typeToast: "", //success / fail
      loading: false,
    };
    this.uploadToClient = this.uploadToClient.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleDeleteCvFile = this.handleDeleteCvFile.bind(this);
  }

  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/custom.js");
  }
  uploadToClient(event) {
    event.stopPropagation();
    if (event.target.files && event.target.files[0]) {
      this.setState({
        cvFile: event.target.files[0],
        cvFileName: event.target.files[0].name,
      });
    }
  }
  handleDeleteCvFile() {
    this.setState({
      cvFile: null,
      cvFileName: "",
    });
  }
  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  checkValidate = (state) => {
    let tempVld = {
      showToast: true,
      message_en: "",
      message_vi: "",
      typeToast: "fail",
    };
    let trigger = false;
    if (!state.fullname) {
      tempVld.message_en = "Please fill your fullname";
      tempVld.message_vi = "Vui lòng điền họ và tên của bạn";
      trigger = true;
    }
    if (trigger === false && !state.email && !state.phone) {
      tempVld.message_en = "Please fill at least your email or phone number";
      tempVld.message_vi =
        "Vui lòng điền ít nhất email hoặc số điện thoại của bạn";
      trigger = true;
    }
    if (trigger) {
      this.setState({ ...tempVld });
      setTimeout(() => {
        this.setState({
          showToast: false,
          message_en: "",
          message_vi: "",
          typeToast: "",
        });
      }, 2000);
      return false;
    }
    return true;
  };
  async submitForm() {
    const validate = this.checkValidate({ ...this.state });
    if (!validate) return;

    this.setState({ loading: true });
    const base64 = await this.toBase64(this.state.cvFile);
    const resForm = new FormData();
    resForm.append("apply", true);
    resForm.append("fullname", this.state.fullname);
    resForm.append("position", this.state.position);
    resForm.append("email", this.state.email);
    resForm.append("phone", this.state.phone);
    resForm.append("message", this.state.message);
    resForm.append("base64", base64);

    const response = await basicAPI.sendmail(resForm);
    let temp = {
      showToast: true,
      message_en: "Something went wrong",
      message_vi: "Có lỗi xảy ra",
      typeToast: "fail",
    };
    if (response && response.result) {
      temp.message_en = response.message_en;
      temp.message_vi = response.message_vi;
      temp.typeToast = "success";
    }
    this.setState({ ...temp, loading: false });
    setTimeout(() => {
      const reset = {
        cvFile: null,
        cvFileName: "",
        fullname: "",
        position: "",
        email: "",
        phone: "",
        message: "",
        showToast: false,
        message_en: "",
        message_vi: "",
        typeToast: "",
        loading: false,
      };
      this.setState({ ...reset });
    }, 4000);
  }
  onChangeInput = (event) => {
    const { value, name } = event.target;
    const temp = {};
    temp[name] = value;
    this.setState({ ...this.state, ...temp });
  };
  render() {
    const lang = this.props.lang;
    const cvFile = this.state.cvFile;
    const cvFileName = this.state.cvFileName;
    const showToast = this.state.showToast;
    const typeToast = this.state.typeToast;
    const loading = this.state.loading;

    return (
      <>
        <div className={`toastmail ${showToast ? typeToast : ""}`}>
          <i
            className={`toasticon fas ${
              typeToast === "success" ? "fa-check-circle" : "fa-times-circle"
            } `}
          ></i>
          <p className="toasttitle">{LANG.INFORMATION[lang]}</p>
          <p className="toastmessage">{this.state[`message_${lang}`]}</p>
        </div>
        <Header />
        <div className="page-career">
          <div className="row row-career">
            <div className="col-md-7">
              <div className="form-apply">
                <div className="career-bold">{LANG.JOIN_OUR_TEAM[lang]}</div>
                <br />
                <p
                  style={{lineHeight:'1.6'}}
                  dangerouslySetInnerHTML={{
                    __html: LANG.DETAIL[lang],
                  }}
                ></p>
                {/* <p>
                  We are always interested in meeting exceptional planning and
                  design professionals. If you would enjoy working in a dynamic
                  environment and are looking for an opportunity to become part
                  of a stellar team of professionals, we invite you to apply
                  today. Please see available opportunities listed on our career
                  portal using the Apply Now link.
                </p>
                <p>Equal Opportunity Employer</p>
                <p>
                  Sasaki is an Equal Opportunity/Affirmative Action Employer and
                  values the strength diversity brings to the workplace. We
                  consider all applicants without regard to race, color,
                  religion, creed, national origin, citizenship status, gender,
                  disability, genetic information, sexual orientation, or
                  veteran status.
                  <br />
                  We are committed to providing reasonable accommodation to
                  applicants with disabilities. If you require a reasonable
                  accommodation in the application or hiring process, please
                  contact the human resources department at
                  humanresources@sasaki.com and include the nature of your
                  request, your name, and preferred method of contact.
                </p>
                <p>E-Verify</p>
                <p>
                  E-Verify is an Internet-based system that allows an employer,
                  using information reported on an employee’s Form I-9,
                  Employment Eligibility Verification, to determine the
                  eligibility of that employee to work in the United States. The
                  E-Verify system is operated by the Department of Homeland
                  Security in partnership with the Social Security
                  Administration.
                </p> */}
              </div>
            </div>
            <div className="col-md-5">
              <div className="form-apply bd-form-app">
                <div className="career-bold text-center">
                  {LANG.APPLICATION[lang]}
                </div>
                <div className="m-t30 formca">
                  <div className="formca-fl">
                    <div className="formca-fl-item">
                      <span>{LANG.FULLNAME[lang]}</span>
                      <input
                        className="input-mini-md"
                        placeholder={LANG.place_fullname[lang]}
                        name="fullname"
                        value={this.state.fullname}
                        onChange={this.onChangeInput}
                      ></input>
                    </div>
                    <div className="formca-fl-item">
                      <span>{LANG.POSITION[lang]}</span>
                      <input
                        className="input-mini-md"
                        placeholder={LANG.place_position[lang]}
                        name="position"
                        value={this.state.position}
                        onChange={this.onChangeInput}
                      ></input>
                    </div>
                  </div>
                  <div className="formca-fl m-t20">
                    <div className="formca-fl-item">
                      <span>Email:</span>
                      <input
                        className="input-mini-md"
                        placeholder={LANG.place_email[lang]}
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeInput}
                      ></input>
                    </div>
                    <div className="formca-fl-item">
                      <span>{LANG.PHONE[lang]}</span>
                      <input
                        className="input-mini-md"
                        placeholder={LANG.place_phone[lang]}
                        name="phone"
                        value={this.state.phone}
                        onChange={this.onChangeInput}
                      ></input>
                    </div>
                  </div>
                  <div className="formca-fl m-t20">
                    <div style={{ width: "100%" }}>
                      <span>{LANG.QUESTION[lang]}</span>
                      <textarea
                        className="input-mini-md textar"
                        placeholder={LANG.place_question[lang]}
                        name="message"
                        value={this.state.message}
                        onChange={this.onChangeInput}
                      ></textarea>
                    </div>
                  </div>

                  <span
                    className="m-t20"
                    style={{
                      color: "gray",
                      fontWeight: 500,
                      display: "block",
                    }}
                  >
                    {LANG.ATTACHMENTS[lang]}
                  </span>
                  <div className="temp-file m-t10">
                    <div className="inputfilebtn">
                      <img src={icon_upload} alt="" />
                      <input
                        type="file"
                        value={""}
                        onChange={this.uploadToClient}
                        className={"upload-btn"}
                      />
                    </div>
                    {cvFileName && (
                      <div className="close-cvfile">
                        <div className="cv-filename">{cvFileName}</div>
                        <img
                          src={icon_close}
                          alt=""
                          onClick={this.handleDeleteCvFile}
                        />
                      </div>
                    )}
                  </div>
                  <button
                    className={`button-27 m-t40 ${loading ? "loadingbtn" : ""}`}
                    onClick={this.submitForm}
                  >
                    {loading ? LANG.SENDING[lang] : LANG.SUBMIT[lang]}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row-career  more-detail">
            {LANG.MORE_ABOUT_THEFIVE[lang]}&nbsp;
            <HashLink smooth to={"/profile"} className="span1">
              {LANG.COMPANY[lang]}
            </HashLink>
            ,&nbsp;
            <HashLink smooth to={"/people"} className="span2">
              {LANG.PEOPLE[lang]}
            </HashLink>
            {LANG.AND[lang]}&nbsp;
            <HashLink smooth to={"/project"} className="span3">
              {LANG.PROJECTS[lang]}
            </HashLink>
            .
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Awards);
