import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import { connect } from "react-redux";
import { basicAPI } from "../data/articles.js";
import dayjs from "dayjs";
dayjs.locale("vi");
var noicon = require("./../../images/noicon.png");

const LANG = {
  nodata: {
    vi: "Chưa có bài viết",
    en: "No news found",
  },
};
class BlogGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      articleData: [],
      articlePagination: {
        currentPage: 0,
        totalPage: [],
      },
    };
  }
  formatUrlThumbnail(url) {
    return url;
  }
  formatDataArticle(data) {
    if (!data || data.length === 0) {
      return [];
    }
    const result = data.map((item) => {
      return {
        id: item.id,
        ...this.formatItemArticle(item.attributes),
      };
    });
    return result;
  }
  formatItemArticle(data) {
    let reviewImg = "";
    const {
      author,
      categories,
      content,
      publishedAt,
      tags,
      thumbnail,
      title_en,
      title_vi,
    } = data;
    if (thumbnail && thumbnail.data && thumbnail.data.attributes) {
      reviewImg = thumbnail.data.attributes.url || "";
      reviewImg = this.formatUrlThumbnail(reviewImg);
    }
    const cusCategories = (categories.data || []).map((i, j) => {
      return { id: i.id, vi: i.attributes.vi, en: i.attributes.en };
    });
    return {
      author: author,
      cusCategories: cusCategories,
      content: content,
      tags: tags,
      reviewImg: reviewImg,
      title_en,
      title_vi,
      day: dayjs(publishedAt).format("DD"),
      month: dayjs(publishedAt).format("MM"),
      year: dayjs(publishedAt).format("YYYY"),
    };
  }
  async fetchListArticle(page) {
    this.setState({
      loading: true,
      articleData: [],
      articlePagination: {
        currentPage: 0,
        totalPage: [],
      },
    });
    const response = await basicAPI.getListArticle(page);
    if (response && response.data && response.meta) {
      this.setState({
        articleData: this.formatDataArticle(response.data),
        articlePagination: response.meta.pagination,
      });
      if (response.meta.pagination) {
        const { page, pageSize, pageCount, total } = response.meta.pagination;
        const rs = [];
        for (let i = 1; i <= pageCount; i++) {
          rs.push(i);
        }
        this.setState({
          articlePagination: {
            currentPage: page,
            totalPage: rs,
          },
        });
      }
      const head = document.getElementById("headernews");
      if (head) {
        head.scrollIntoView({ behavior: "smooth" });
      }
    }
    this.setState({ loading: false });
  }
  async componentDidMount() {
    let page = 1;
    const urlParams = new URLSearchParams(window.location.search);
    const pageUrl = urlParams.get("page");
    if (!isNaN(pageUrl)) {
      page = Number(pageUrl);
      await this.fetchListArticle(page);
    }
  }
  renderTempItem(data, lang) {
    let tempLang = "en";
    if (lang && lang === "vi") {
      tempLang = "vi";
    }
    if (data && data.length) {
      return (
        <div style={{ minHeight: "50px" }}>
          {data.map((i, j) => (
            <span key={j} className="ant-tag">
              {i[tempLang]}
            </span>
          ))}
        </div>
      );
    }
    return <></>;
  }
  async goTo(current, next) {
    if (current === next) return;
    const { articlePagination } = this.state;
    if (typeof next === "boolean") {
      let temp = next ? current + 1 : current - 1;
      this.setState({
        articlePagination: { ...articlePagination, currentPage: temp },
      });
      await this.fetchListArticle(temp);
    }
    if (typeof next === "number") {
      this.setState({
        articlePagination: { ...articlePagination, currentPage: next },
      });
      await this.fetchListArticle(next);
    }
  }
  render() {
    const lang = this.props.lang;
    const articleData = this.state.articleData;
    const loading = this.state.loading;
    const { currentPage, totalPage } = this.state.articlePagination;
    return (
      <>
        <div id="headernews"></div>
        <Header />
        <div  className="">
          <div className="section-full p-tb80 bg-white inner-page-padding">
            <div className="container">
              {loading === false && articleData.length === 0 ? (
                <div className="nodata">
                  <img src={noicon} alt="" />
                  <p>{LANG.nodata[lang]}</p>
                </div>
              ) : null}
              <div className=" mfp-gallery news-grid clearfix row ">
                {loading ? (
                  <>
                    {[1, 2, 3, 4, 5, 6].map((item, index) => (
                      <div
                        className=" col-lg-4 col-md-6 col-sm-12 m-b15"
                        key={index}
                      >
                        <div className="skeleton">
                          <div className="fakeimg"></div>
                          <div className="faketitle"></div>
                          <div className="fakeauthor"></div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {articleData.map((item, index) => (
                      <div
                        className=" col-lg-4 col-md-6 col-sm-12 m-b15"
                        key={index}
                      >
                        <a href={`/news/${item.id}`}>
                          <div className="blog-post blog-grid date-style-2 cus-ar-bd">
                            <div className="sx-post-media sx-img-effect img-reflection cus-thumb">
                              <img src={item.reviewImg} alt="" />
                            </div>
                            <div className="sx-post-info p-t15 p-l15 p-r15 p-b15">
                              <div className="sx-post-meta ">
                                <ul>
                                  <li className="post-date">
                                    <strong>{item.day}</strong>{" "}
                                    <span>{item.month}</span>{" "}
                                  </li>
                                  <li className="post-author cus-author">
                                    {item.author || ""}
                                  </li>
                                  <li className="post-comment">
                                    {" "}
                                    {item.comments}
                                  </li>
                                </ul>
                              </div>
                              <div className="sx-post-title">
                                <h4
                                  style={{ minHeight: "53px" }}
                                  className="post-title row2lines"
                                >
                                  {item[`title_${lang}`]}
                                </h4>
                              </div>
                              {this.renderTempItem(item.cusCategories, lang)}
                              <div className="render-i"></div>
                              <div></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {articleData && articleData.length ? (
                <ul key={currentPage} className="pagination m-t30 m-b0">
                  {totalPage.length && currentPage > 1 ? (
                    <li onClick={() => this.goTo(currentPage, false)}>
                      <a>«</a>
                    </li>
                  ) : null}
                  {totalPage.map((i, j) => (
                    <li
                      key={j}
                      className={currentPage === i ? "active" : ""}
                      onClick={() => this.goTo(currentPage, i)}
                    >
                      <a>{i}</a>
                    </li>
                  ))}
                  {totalPage.length && currentPage < totalPage.length ? (
                    <li onClick={() => this.goTo(currentPage, true)}>
                      <a>»</a>
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(BlogGrid);
