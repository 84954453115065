import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Projects6 from "./../Elements/Projects6";
import { useParams } from "react-router-dom";
import { basicAPI } from "../data/articles.js";
import { connect } from "react-redux";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const LANG = {
  investor: {
    en: "INVESTOR",
    vi: "CHỦ ĐẦU TƯ",
  },
  scope: {
    en: "SCOPE OF WORK",
    vi: "PHẠM VI CÔNG VIỆC",
  },
  role: {
    en: "ROLE",
    vi: "VAI TRÒ",
  },
  scale: {
    en: "SCALE",
    vi: "QUY MÔ",
  },
  location: {
    en: "LOCATION",
    vi: "VỊ TRÍ",
  },
  description: {
    en: "DESCRIPTION",
    vi: "MÔ TẢ",
  },
};

const withRouter = (Component) => (props) => {
  const params = useParams();
  return <Component params={params} {...props} />;
};
class ProjectDetail1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file_id: this.props.params.file_id,
      name_en: "",
      name_vi: "",
      investor_en: "",
      investor_vi: "",
      scopeofwork_en: "",
      scopeofwork_vi: "",
      role_en: "",
      role_vi: "",
      location_en: "",
      location_vi: "",
      description_en: "",
      description_vi: "",
      scale_en: "",
      scale_vi: "",
      video_link: "",
      id_mp4: "",
      categories: [],
      thumbnail: {
        height: 0,
        width: 0,
        url: "",
      },
      list_image: [],
      list_similar: [],
      photoIndex: 0,
      isOpen: false,
      realHeight: 0,
    };
  }

  async componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");

    const temp = await basicAPI.getProjectDetail(this.state.file_id);
    if (temp && temp.id) {
      this.setState({ ...this.state, ...temp });
      if (window && window.innerWidth) {
        const realHeight =
          (window.innerWidth * temp.thumbnail.height) / temp.thumbnail.width;
        this.setState({ realHeight });
      }
    }
    if (temp && temp.id && temp.categories && temp.categories.length) {
      const cateString = temp.categories.map((i) => i.name);
      let similar = await basicAPI.getSimilarProject(cateString);
      if (similar && similar.length) {
        similar = similar.filter(
          (item) => item.id + "" !== this.state.file_id + ""
        );
      }
      this.setState({ ...this.state, list_similar: similar });
    }
  }
  render() {
    const lang = this.props.lang;
    const { photoIndex, isOpen, list_image, realHeight } = this.state;
    const pageStyle = {
      marginTop: 5,
    };

    return (
      <>
        <Header />
        <div className="projectdetail" style={pageStyle}>
          {this.state.thumbnail.url ? (
            <Banner
              title={""}
              pagename={""}
              lang={""}
              description=""
              classname="bg-project-detail"
              height={realHeight}
              bgimage={this.state.thumbnail.url}
            />
          ) : (
            <></>
          )}

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding stick_in_parent basic-color-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7  sticky_column">
                  <div className="project-detail-containt">
                    <div className="bg-white text-black">
                      <h3>{this.state[`name_${lang}`]}</h3>
                      <div className="m-b20">
                        {this.state.categories.map((i, j) => (
                          <>
                            <span key={j} className="ant-tag">
                              {i[lang]}
                            </span>
                          </>
                        ))}
                      </div>
                      <div className="product-block">
                        <ul>
                          <li>
                            <h4 className="m-b10">{LANG.investor[lang]}</h4>
                            <p>{this.state[`investor_${lang}`]}</p>
                          </li>
                          <li>
                            <h4 className="m-b10">{LANG.scope[lang]}</h4>
                            <p>{this.state[`scopeofwork_${lang}`]}</p>
                            <p>{this.state[`role_${lang}`]}</p>
                          </li>
                          {/* <li>
                            <h4 className="m-b10">{LANG.role[lang]}</h4>
                            <p>{this.state[`role_${lang}`]}</p>
                          </li> */}
                          <li>
                            <h4 className="m-b10">{LANG.scale[lang]}</h4>
                            <p>{this.state[`scale_${lang}`]}</p>
                          </li>
                          <li>
                            <h4 className="m-b10">{LANG.location[lang]}</h4>
                            <p>{this.state[`location_${lang}`]}</p>
                          </li>
                          <li>
                            <h4 className="m-b10">{LANG.description[lang]}</h4>
                            <p>{this.state[`description_${lang}`]}</p>
                          </li>
                          {this.state.id_mp4 ? (
                            <li>
                              <h4 className="m-b10">VIDEO</h4>
                              <iframe
                                src={`https://www.youtube.com/embed/${this.state.id_mp4}`}
                                frameborder="0"
                                allowfullscreen="allowfullscreen"
                                mozallowfullscreen="mozallowfullscreen"
                                msallowfullscreen="msallowfullscreen"
                                oallowfullscreen="oallowfullscreen"
                                webkitallowfullscreen="webkitallowfullscreen"
                                width="100%"
                                height="400px"
                                title="video"
                              />
                            </li>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                      <div className="m-b0">
                        <div className="sx-divider divider-1px  bg-black">
                          <i className="icon-dot c-square" />
                        </div>
                      </div>

                      {/* <ul className="social-icons social-square social-darkest m-b0">
                        <li>
                          <a
                            href="https://www.facebook.com"
                            target="_blank"
                            className="fa fa-facebook"
                          />
                        </li>
                        <li>
                          <a
                            href="https://twitter.com"
                            target="_blank"
                            className="fa fa-twitter"
                          />
                        </li>
                        <li>
                          <a
                            href="https://in.linkedin.com"
                            target="_blank"
                            className="fa fa-linkedin"
                          />
                        </li>
                        <li>
                          <a
                            href="https://rss.com"
                            target="_blank"
                            className="fa fa-rss"
                          />
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com"
                            target="_blank"
                            className="fa fa-youtube"
                          />
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com"
                            target="_blank"
                            className="fa fa-instagram"
                          />
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 ">
                  <div className="project-detail-outer">
                    {this.state.list_image.map((imageLink, index) => (
                      <div key={index} className="project-detail-pic m-b10">
                        <div className="sx-media">
                          <img
                            src={imageLink}
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ isOpen: true, photoIndex: index })
                            }
                          />
                        </div>
                      </div>
                    ))}
                    {/* <div className="sx-box">
                      <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                        <img
                          src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg"
                          alt=""
                        />
                        <NavLink
                          to={"#"}
                          className="play-now"
                          data-toggle="modal"
                          data-target="#myModal5"
                        >
                          <i className="icon fa fa-play" />
                          <span className="ripple" />
                        </NavLink>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/*
                            <div className="project-detail-containt-2 m-t50">
                                <h3>Creating a sustainable future through building preservation, green architecture, and smart design</h3>
                                <p className="m-b0"> Designers think everything done by someone else is awful, and that they could do it better themselves, which explains why I designed my own living room carpet, I suppose. the architect represents neither a Dionysian nor an Apollinian condition: here it is the mighty act of will, the will which moves mountains, the intoxication of the strong will, which demands artistic expression. The most powerful men have always inspired the architects; the architect has always been influenced by power.</p>
                            </div>
                            */}
            </div>
          </div>
          {/* SECTION CONTENT END  */}
          <Projects6 similars={this.state.list_similar} />
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={list_image[photoIndex]}
            nextSrc={list_image[(photoIndex + 1) % list_image.length]}
            prevSrc={
              list_image[
                (photoIndex + list_image.length - 1) % list_image.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (photoIndex + list_image.length - 1) % list_image.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % list_image.length,
              })
            }
          />
        )}

        {/* <div className="modal fade" id="myModal5" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
            </div>
          </div>
        </div> */}
        <Footer />
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(ProjectDetail1)
);
