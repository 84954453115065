import React from "react";
import { NavLink } from "react-router-dom";

class Banner extends React.Component {
  checkTextHome(lang) {
    let home = "Home";
    if (lang && lang === "vi") {
      home = "Trang chủ";
    }
    return home;
  }
  convertStyleBg(bgimage, newHeight) {
    const temp = { backgroundImage: "url(" + this.props.bgimage + ")" };
    if (newHeight && typeof newHeight === "number") {
      //   temp.height = newHeight + "px";
    }
    return temp;
  }
  render() {
    return (
      <>
        <div
          className={`sx-bnr-inr overlay-wraper bg-parallax bg-top-right ${
            this.props.classname || ""
          }`}
          data-stellar-background-ratio="0.5"
          style={this.convertStyleBg(this.props.bgimage, this.props.height)}
        >
          <div className="overlay-main bg-black opacity-01" />
          <div className="container">
            <div className="sx-bnr-inr-entry">
              <div className="banner-title-outer">
                <div className="banner-title-name">
                  <h2 className="m-tb0">{this.props.title}</h2>{" "}
                  <p>{this.props.description}</p>
                </div>
              </div>
              {/* BREADCRUMB ROW */}
              {this.props.isHideContent != true ? (
                <div>
                  <ul className="sx-breadcrumb breadcrumb-style-2">
                    <li>
                      <NavLink to={"./"}>
                        {this.checkTextHome(this.props.lang)}
                      </NavLink>
                    </li>
                    <li>{this.props.pagename}</li>
                  </ul>
                </div>
              ) : (
                <></>
              )}

              {/* BREADCRUMB ROW END */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Banner;
