import React from "react";
import { connect } from "react-redux";

var company_size_en_img = require("./../../images/our-team5/company_size_en.jpg");
var company_size_vi_img = require("./../../images/our-team5/company_size_vi.jpg");

const LANG = {
  company_size: {
    vi: "Quy mô công ty",
    en: "Company size",
  },
  describe: {
    vi: "<span>The Five & Partner</span> đã cho thấy sự tăng trưởng trong quy mô doanh nghiệp từ 5-10 nhân viên ngày đầu thành lập cho đến hơn 40 nhân viên trong 13 năm và sẽ không ngừng tăng trưởng trong tương lai.",
    en: "<span>The Five & Partner</span> has witnessed an increase in the number of employees from around 5 people to approximately 40 people in 13 years and promise constantly growing up in the future.",
  },
  boardMembers: {
    vi: "Hội đồng quản trị",
    en: "Board members",
  },
  people: {
    vi: "Nhân sự",
    en: "People",
  },
};

class Team1 extends React.Component {
  render() {
    const lang = this.props.lang;
    const hasStaff = this.props.hasStaff;

    return (
      <>
        <div
          id="people"
          className="section-full p-t80 p-b50 mobile-page-padding"
        >
          <div className="container">
            {/* Company size here */}
            {hasStaff ? (
              <>
                <div className="section-head">
                  <div className="sx-separator-outer separator-left">
                    <div className="sx-separator">
                      <h3 className="sep-line-one">
                        {LANG.company_size[lang]}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="describe-bl">
                  <img
                    src={
                      lang === "en" ? company_size_en_img : company_size_vi_img
                    }
                    alt=""
                  />
                </div>

                <p
                  className="describe-people"
                  dangerouslySetInnerHTML={{ __html: LANG.describe[lang] }}
                ></p>
              </>
            ) : null}

            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator bg-moving">
                  <h3 className="sep-line-one">{LANG.boardMembers[lang]}</h3>
                </div>
              </div>
            </div>

            <div className="section-content">
              <div className="row list-people">
                {teamMembers.map((item, index) => (
                  <div className=" m-b20 item-people" key={index}>
                    <div className="our-team-2 ">
                      <div className="profile-image">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="figcaption text-black">
                        <h4 className="m-t0">{item.membername[lang]}</h4>
                        <span
                          className="m-b0"
                          dangerouslySetInnerHTML={{
                            __html: item.position[lang],
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {hasStaff ? (
              <>
                <div className="section-head m-t30">
                  <div className="sx-separator-outer separator-left">
                    <div className="sx-separator bg-moving">
                      <h3 className="sep-line-one">{LANG.people[lang]}</h3>
                    </div>
                  </div>
                </div>

                <div className="section-content">
                  <div className="row list-people">
                    {teamStaffs.map((item, index) => (
                      <div className=" m-b20 item-staff" key={index}>
                        <div className="our-team-2">
                          <div className="tease-img profile-image">
                            <img
                              src={item.image}
                              alt={"Thefive - " + item.membername[lang]}
                            />
                          </div>
                          <div className="tease-titles">
                            <h1 className="tease-name">
                              {item.membername[lang]}
                            </h1>
                            <p
                              className="tease-position"
                              dangerouslySetInnerHTML={{
                                __html: item.position[lang],
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};
var teamMembers = [
  {
    image: require("./../../images/our-team5/img_people1.png"),
    membername: {
      vi: "PGS.TS.KTS TRỊNH DUY ANH",
      en: "Assoc.Prof.PhD.Arch TRINH DUY ANH",
    },
    position: {
      vi: "<span class='font-weight-600'>CHỦ TỊCH HỘI ĐỒNG</span> - Chủ nhiệm, chủ trì thiết kế kiến trúc, thiết kế quy hoạch",
      en: "<span class='font-weight-600'>CHAIRMAN OF DIRECTORS</span> - Lead the design of Architecture, Urban Planning",
    },
  },
  {
    image: require("./../../images/our-team5/img_people2.png"),
    membername: {
      vi: "ThS.KTS LÊ HỒNG QUANG",
      en: "M.Arch LE HONG QUANG ",
    },
    position: {
      vi: "<span class='font-weight-600'>TỔNG GIÁM ĐỐC</span> - Chủ nhiệm, chủ trì thiết kế kiến trúc; chủ nhiệm, chủ trì thiết kế quy hoạch",
      en: "<span class='font-weight-600'>GENERAL MANAGER</span> - Lead the design of Architecture and Urban Planning",
    },
  },
  {
    image: require("./../../images/our-team5/img_people3.png"),
    membername: {
      vi: "ThS.KTS HỒ ĐÌNH CHIÊU",
      en: "M.Arch HO DINH CHIEU",
    },
    position: {
      vi: "<span class='font-weight-600'>PHÓ TỔNG GIÁM ĐỐC</span> - Chủ nhiệm, chủ trì thiết kế kiến trúc; chủ nhiệm, chủ trì thiết kế quy hoạch",
      en: "<span class='font-weight-600'>VICE GENERAL DIRECTOR</span> - Lead the design of Architecture and Urban Planning",
    },
  },
  {
    image: require("./../../images/our-team5/img_people4.png"),
    membername: {
      vi: "ThS.KTS NGUYỄN HÀ CƯƠNG",
      en: "M.Arch NGUYEN HA CUONG",
    },
    position: {
      vi: "<span class='font-weight-600'>PHÓ TỔNG GIÁM ĐỐC</span> - Chủ nhiệm, chủ trì thiết kế kiến trúc; chủ nhiệm, chủ trì thiết kế quy hoạch",
      en: "<span class='font-weight-600'>VICE GENERAL DIRECTOR</span> - Lead the design of Architecture and Urban Planning",
    },
  },
  {
    image: require("./../../images/our-team5/img_people5.png"),
    membername: {
      vi: "TS.KTS TRƯƠNG QUỐC SỬ",
      en: "PhD.Arch TRUONG QUOC SU",
    },
    position: {
      vi: "<span class='font-weight-600'>GIÁM ĐỐC KẾ HOẠCH ĐẦU TƯ</span> - Chủ nhiệm, chủ trì thiết kế kiến trúc, thiết kế quy hoạch",
      en: "<span class='font-weight-600'>CHIEF INVESTMENT OFFICER</span> - Lead the design of Architecture, Urban Planning",
    },
  },
  {
    image: require("./../../images/our-team5/img_people6.png"),
    membername: {
      vi: "TS. LEE YOUNG II",
      en: "PhD. LEE YOUNG II",
    },
    position: {
      vi: "<span class='font-weight-600'>CỐ VẤN CHUYÊN MÔN</span> - Giáo sư từ trường Đại học kobe - Nhật Bản",
      en: "<span class='font-weight-600'>Speacialist</span> - Professor from Kobe University - Japan",
    },
  },
];
var teamStaffs = [
  {
    image: require("./../../images/our-team5/img_staff01.png"),
    membername: {
      vi: "Từ Thanh Hoàng Tiến",
      en: "Tu Thanh Hoang Tien",
    },
    position: {
      vi: "Trường phòng thiết kế kiến trúc - Thạc sĩ, Kiến trúc sư",
      en: "Architectural Manager-M.Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff02.png"),
    membername: {
      vi: "Đào Quốc Vấn",
      en: "Dao Quoc Van",
    },
    position: {
      vi: "Phó phòng thiết kế kiến trúc - Kiến trúc sư",
      en: "Deputy Architectural Manager - Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff03.png"),
    membername: {
      vi: "Nguyễn Văn Diễn",
      en: "Nguyen Van Dien",
    },
    position: {
      vi: "Phó phòng thiết kế kiến trúc - Kiến trúc sư",
      en: "Deputy Architectural Manager - Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff05.png"),
    membername: {
      vi: "Cao Thiên An",
      en: "Cao Thien An",
    },
    position: {
      vi: "Trưởng phòng thiết kế Quy hoạch - Thạc sĩ, Kiến trúc sư",
      en: "Urban design Manager M.Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff04.png"),
    membername: {
      vi: "Phạm Quang Thắng",
      en: "Pham Quang Thang",
    },
    position: {
      vi: "Trưởng phòng thiết kế nội thất - Kiến trúc sư Nội thất",
      en: "Interior design Manager Interior Design",
    },
  },

  {
    image: require("./../../images/our-team5/img_staff06.png"),
    membername: {
      vi: "Nguyễn Trần Trung Nguyên",
      en: "Nguyen Tran Trung Nguyen",
    },
    position: {
      vi: "Phó phòng thiết kế Quy hoạch - Kiến trúc sư",
      en: "Deputy Urban design Manager - Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff07.png"),
    membername: {
      vi: "Trần Công Tuấn Anh",
      en: "Tran Cong Tuan Anh",
    },
    position: {
      vi: "Chuyên viên thiết kế - Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff08.png"),
    membername: {
      vi: "Lê Thị Thảo",
      en: "Le Thi Thao",
    },
    position: {
      vi: "Chuyên viên quản lý BIM - Kiến trúc sư",
      en: "BIM Management - Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff09.png"),
    membername: {
      vi: "Nguyễn Thị Thùy Cẩm",
      en: "Nguyen Thi Thuy Cam",
    },
    position: {
      vi: "Quản lý nhân sự",
      en: "Human Resource Management",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff10.png"),
    membername: {
      vi: "Nguyễn Thị Thanh Hải",
      en: "Nguyen Thi Thanh Hai",
    },
    position: {
      vi: "Kế toán trưởng",
      en: "Chief Accountant",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff11.png"),
    membername: {
      vi: "Võ Lê Quang Huy",
      en: "Vo Le Quang Huy",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff12.png"),
    membername: {
      vi: "Trương Việt Khánh",
      en: "Truong Viet Khanh",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff13.jpg"),
    membername: {
      vi: "Trần Minh Đàm",
      en: "Tran Minh Dam",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff14.png"),
    membername: {
      vi: "Võ Công Tráng",
      en: "Vo Cong Trang",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff15.png"),
    membername: {
      vi: "Nguyễn Hoàng Hải",
      en: "Nguyen Hoang Hai",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff16.png"),
    membername: {
      vi: "Trần Duy Phong",
      en: "Tran Duy Phong",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff17.png"),
    membername: {
      vi: "Nguyễn Khánh Tiên",
      en: "Nguyen Khanh Tien",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff18.png"),
    membername: {
      vi: "Nguyễn Việt Cảnh Tân",
      en: "Nguyen Viet Canh Tan",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff19.png"),
    membername: {
      vi: "Nguyễn Hạnh Nguyên",
      en: "Nguyen Hanh Nguyen",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff24.png"),
    membername: {
      vi: "Tống Thảo Nguyên",
      en: "Tong Thao Nguyen",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff21.jpg"),
    membername: {
      vi: "Võ Thành Phát",
      en: "Vo Thanh Phat",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff22.png"),
    membername: {
      vi: "Nguyễn Ngọc Minh Khánh",
      en: "Nguyen Ngoc Minh Khanh",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff23.png"),
    membername: {
      vi: "Võ Tuấn Kiệt",
      en: "Vo Tuan Kiet",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff25.png"),
    membername: {
      vi: "Phạm Đình Anh Khoa",
      en: "Pham Dinh Anh Khoa",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff26.png"),
    membername: {
      vi: "Phan Nguyễn Nguyên Nhi",
      en: "Phan Nguyen Nguyen Nhi",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff27.png"),
    membername: {
      vi: "Nguyễn Xuân Phước",
      en: "Nguyen Xuan Phuoc",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff28.png"),
    membername: {
      vi: "Mai Thanh Hậu",
      en: "Mai Thanh Hau",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff29.png"),
    membername: {
      vi: "Nguyễn Chiến Công",
      en: "Nguyen Chien Cong",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
  {
    image: require("./../../images/our-team5/img_staff30.png"),
    membername: {
      vi: "Đỗ Phương Quyên",
      en: "Do Phuong Uyen",
    },
    position: {
      vi: "Kiến trúc sư",
      en: "Architect",
    },
  },
];

export default connect(mapStatetoProps, mapDispatchtoProps)(Team1);
