import axios from "axios";
import qs from "qs";

const apiToken =
  "a3be66e326349476b875fdb697fe20f8e464a035235c3934f49fab67f333d389f03e562d72f819dbbecfe711d17d13c74027061d5427304f8219c7fa5aee9f59981b4e10e802a54f1b73cb9320cc94ca230b773f69e6846ba94c57e5ff115b5a4355b178b19391f7932ba2a1bf21dc4c35e90bf3cd2c39551128ed0037520f9f";

const headers = { headers: { Authorization: `Bearer ${apiToken}` } };
const getUrlApiServer = () => {
  let apiServer = window.location.hostname;
  let scheme = window.location.protocol;

  if (apiServer.includes("localhost")) {
    if (scheme) {
      apiServer = `${scheme}//localhost:1337`;
    }
  }
  if (apiServer.includes(".com.vn")) {
    // TODO: FOR production
    if (scheme) {
      apiServer = `${scheme}//manage.thefive.com.vn`;
    }
  }
  // console.log('http ',apiServer)
  // return `${apiServer}/api`;
  return `https://manage.thefive.com.vn/api`;
};

export const basicAPI = {
  getListProject: async () => {
    try {
      const url = `${getUrlApiServer()}/projectlist`;
      const { data } = await axios.get(url, headers);
      const result = {
        projects: data.project_list,
      };
      return result;
    } catch (error) {
      return null;
    }
  },
  getProjectDetail: async (id) => {
    try {
      const data_params = { project_id: id };
      const url = getUrlApiServer() + "/projectdetail";
      const { data } = await axios.post(url, data_params, headers);
      return data;
    } catch (error) {
      return {};
    }
  },
  getSimilarProject: async (cateString) => {
    try {
      const data_params = { cateString: cateString };
      const url = getUrlApiServer() + "/projectsimilar";
      const { data } = await axios.post(url, data_params, headers);
      return data;
    } catch (error) {
      return {};
    }
  },

  getListArticle: async (page) => {
    try {
      const query = qs.stringify(
        {
          populate: "*",
          sort: ["publishedAt:desc"],
          pagination: {
            page: page,
            pageSize: 9,
          },
        },
        {
          encodeValuesOnly: true,
        }
      );
      const url = `${getUrlApiServer()}/articles?${query}`;
      const { data } = await axios.get(url, headers);
      return data;
    } catch (error) {
      return null;
    }
  },
  getDetailArticle: async (id) => {
    try {
      const query = qs.stringify(
        {
          populate: "*",
        },
        {
          encodeValuesOnly: true,
        }
      );
      const url = getUrlApiServer() + "/articles/" + id + "?" + query;
      const { data } = await axios.get(url, headers);
      return data;
    } catch (error) {
      return null;
    }
  },
  sendmail: async (resForm) => {
    try {
      const url = getUrlApiServer() + "/sendmail";
      const response = await axios.post(url, resForm, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data || null;
    } catch (error) {
      return null;
    }
  },
};
