import React from "react";
import Footer from "./../Common/Footer";

import Header from "./../Common/Header";
import AwardsList from "./../Elements/AwardsList";
import { connect } from "react-redux";

const LANG = {
  title: {
    vi: "Giải thưởng",
    en: "Awards",
  },
  pagename: {
    vi: "Giải thưởng",
    en: "Awards",
  },
  description: {
    vi: "Bản chất của thiết kế nội thất sẽ luôn là về con người và cách họ sống. Đó là về thực tế của những gì tạo nên một sự hấp dẫn, văn minh.",
    en: "The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized.",
  },
};
class Awards extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/custom.js");
  }
  render() {
    const lang = this.props.lang;

    return (
      <>
        <Header />
        <div className="">
          {/* <Banner
            title={LANG.title[lang]}
            pagename={LANG.pagename[lang]}
            lang={lang}
            description={LANG.description[lang]}
            bgimage={bnrimg}
          /> */}

          {/* <About2 />
          <WhatWeDo6 />
          <Achievements1 />
          <Team />
          <Testimonials2 separatoralignment="separator-center" /> */}

          <AwardsList />
        </div>

        <Footer />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Awards);
