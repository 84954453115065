import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { connect } from "react-redux";
import { projectList } from "../../common/lang";
import { buildQueries } from "@testing-library/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const menu = [...projectList];
const LANG = {
  projects: {
    vi: "Dự án",
    en: "Projects",
  },
  aboutus: {
    vi: "Giới thiệu",
    en: "About us",
  },
  expertise: {
    vi: "Chuyên môn",
    en: "Expertise",
  },
  news: {
    vi: "Tin tức",
    en: "News",
  },
  profile: {
    vi: "Hồ sơ năng lực",
    en: "Company profile",
  },
  people: {
    vi: "Quy mô công ty",
    en: "Our company",
  },
  awards: {
    vi: "Giải thưởng",
    en: "Our Rewards",
  },
  partners: {
    vi: "Đối tác chiến lược",
    en: "Strategic Partners",
  },
  careers: {
    vi: "Tuyển dụng",
    en: "Careers",
  },
};
class Navigation extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/mobilenav.js");
    let category = "#all";
    const tempFilter = localStorage.getItem("valueProject");
    if (tempFilter && tempFilter.length) {
      category = tempFilter;
    }
    localStorage.setItem("valueProject", category);
    this.props.ActiveProjectType(category);
  }
  handleClickNav = (value) => {
    localStorage.setItem("valueProject", value);
    this.props.ActiveProjectType(value);
    if (window.location.pathname !== "/project") {
      const link = document.createElement("a");
      link.setAttribute("href", "/project");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  render() {
    const lang = this.props.lang;
    return (
      <>
        <ul className="nav navbar-nav">
          <li className="active">
            {/*
                            <NavLink to={""}>Home</NavLink>
                            <ul className="sub-menu">
                            <li><NavLink to={"/"}>Home-1</NavLink></li>
                            <li><NavLink to={"/home-2"}>Home-2</NavLink></li>
                            <li><NavLink to={"/home-3"}>Home-3</NavLink></li>
                            <li><NavLink to={"/home-4"}>Home-4</NavLink></li>
                            <li><NavLink to={"/home-5"}>Home-5</NavLink></li>
                            <li><NavLink to={"/home-6"}>Home-6</NavLink></li>
                            </ul>
                        */}
          </li>
          <li>
            <NavLink to={""}>{LANG.projects[lang]}</NavLink>
            <ul className="sub-menu" id="new-menu">
              {menu.map((sub, index) => (
                <li key={index}>
                  <a onClick={() => this.handleClickNav(sub.id)}>
                    {sub.name[lang]}
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li>
            <NavLink to={""}>{LANG.aboutus[lang]}</NavLink>
            <ul className="sub-menu" id="new-menu">
              <li>
                <NavLink to={"/profile"}>{LANG.profile[lang]}</NavLink>
              </li>
              <li>
                <HashLink smooth to={"/people"}>
                  {LANG.people[lang]}
                </HashLink>
              </li>
              <li>
                <NavLink to={"/awards"}>{LANG.awards[lang]}</NavLink>
              </li>
              <li>
                <NavLink to={"/partners"}>{LANG.partners[lang]}</NavLink>
              </li>
              {/* TODO: Reopen careers */}
              <li>
                <NavLink to={"/careers"}>{LANG.careers[lang]}</NavLink>
              </li>
            </ul>
          </li>
          <li>
            <HashLink smooth to={"/#expertise"}>
              {LANG.expertise[lang]}
            </HashLink>
            {/*
                        <ul className="sub-menu">
                            <li>
                                <NavLink to={""}>Services</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={"/services-1"}>Services 1</NavLink></li>
                                    <li><NavLink to={"/services-2"}>Services 2</NavLink></li>
                                    <li><NavLink to={"/services-detail"}>Service Detail</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={""}>Team</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={"/team-1"}>Our Team 1</NavLink></li>
                                    <li><NavLink to={"/team-2"}>Our Team 2</NavLink></li>
                                    <li><NavLink to={"/team-single"}>Team Detail</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to={"/our-history"}>Our History</NavLink></li>
                            <li><NavLink to={"/error-404"}>Error 404</NavLink></li>
                        </ul>
                        */}
          </li>
          {/* TODO: reopen news */}
          <li>
            <NavLink to={"/news"}>{LANG.news[lang]}</NavLink>
          </li>
        </ul>
      </>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Navigation {...props} navigate={navigate} />;
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    ActiveProjectType: (value) => {
      dispatch({ type: "project", value: value });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(WithNavigate);
