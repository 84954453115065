import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { basicAPI } from "../data/articles.js";
import dayjs from "dayjs";
import { connect } from "react-redux";

const withRouter = (Component) => (props) => {
  const params = useParams();
  return <Component params={params} {...props} />;
};
const LANG = {
  author: {
    vi: "Tin, ảnh: ",
    en: "By: ",
  },
  timePublish: {
    vi: "Cập nhật: ",
    en: "Publish at: ",
  },
  latest: {
    vi: "Tin mới nhất",
    en: "Latest News",
  },
};
class BlogSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latestArticle: [],
      loading: true,
      author: "",
      cusCategories: [],
      content_en: "",
      content_vi: "",
      tags: [],
      reviewImg: "",
      title: "",
      day: null,
      month: null,
      year: null,
      timePublish: "",
      video: "",
    };
  }
  formatUrlThumbnail(url) {
    return url;
  }
  formatDataArticle(data) {
    if (!data || data.length === 0) {
      return [];
    }
    const result = data.map((item) => {
      return {
        id: item.id,
        ...this.formatItemArticle(item.attributes),
      };
    });
    return result;
  }
  formatItemArticle(data) {
    let reviewImg = "";
    const {
      author,
      categories,
      content_en,
      content_vi,
      publishedAt,
      tags,
      thumbnail,
      title_en,
      title_vi,
      video,
    } = data;
    if (thumbnail && thumbnail.data && thumbnail.data.attributes) {
      reviewImg = thumbnail.data.attributes.url || "";
      reviewImg = this.formatUrlThumbnail(reviewImg);
    }
    const cusCategories = (categories.data || []).map((i, j) => {
      return { id: i.id, vi: i.attributes.vi, en: i.attributes.en };
    });
    return {
      author: author,
      cusCategories: cusCategories,
      content_en,
      content_vi,
      tags: tags,
      reviewImg: reviewImg,
      title_en,
      title_vi,
      day: dayjs(publishedAt).format("DD"),
      month: dayjs(publishedAt).format("MM"),
      year: dayjs(publishedAt).format("YYYY"),
      timePublish: dayjs(publishedAt).format("HH:mm DD-MM-YYYY"),
      video: video || "",
    };
  }
  async componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }
    loadScript("./assets/js/custom.js");

    const news_id = this.props.params.news_id;
    if (news_id && typeof Number(news_id) === "number") {
      const response = await basicAPI.getDetailArticle(news_id);
      const responseList = await basicAPI.getListArticle(1);

      if (response && response.data) {
        this.setState({
          loading: false,
          ...this.formatItemArticle(response.data.attributes),
        });
      } else {
        this.props.navigate("/404");
      }
      if (responseList && responseList.data) {
        let dataFormat = this.formatDataArticle(responseList.data);
        dataFormat = dataFormat
          .filter((item) => item.id !== Number(news_id))
          .slice(0, 3);
        this.setState({
          latestArticle: dataFormat,
        });
      }
      const head = document.getElementById("headernews");
      if (head) {
        head.scrollIntoView({ behavior: "smooth" });
      }
      return;
    }
    this.props.navigate("/404");
  }
  renderTempItem2(video_url) {
    let result_url = video_url;
    if (
      video_url &&
      video_url.length &&
      video_url.includes("https://www.youtube.com")
    ) {
      result_url = `https://www.youtube.com/embed/`;
      try {
        const paramsMeter = new URL(video_url).searchParams;
        const nameId = paramsMeter.get("v");
        if (nameId && nameId.length) {
          result_url = result_url + nameId;
        } else {
          result_url = "";
        }
      } catch (error) {
        result_url = "";
      }
    }
    if (result_url) {
      return (
        <>
          <iframe
            src={result_url}
            frameborder="0"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
            width="100%"
            height="100%"
            style={{ aspectRatio: "16 / 9" }}
            title="video"
          />
        </>
      );
    }
    return <></>;
  }
  renderTempItem(data, lang) {
    let tempLang = "en";
    if (lang && lang === "vi") {
      tempLang = "vi";
    }
    if (data && data.length) {
      return (
        <>
          {data.map((i, j) => (
            <span key={j} className="ant-tag">
              {i[tempLang]}
            </span>
          ))}
        </>
      );
    }
    return <></>;
  }
  render() {
    const {
      author,
      cusCategories,
      content_en,
      content_vi,
      reviewImg,
      timePublish,
      title_en,
      title_vi,
      latestArticle,
      video,
    } = this.state;
    console.log("video ", video);
    const lang = this.props.lang;
    return (
      <>
        <div id="headernews" className="">
          <Header />
          <div className="section-full p-t80 p-b50 inner-page-padding">
            <div className="container">
              <div className="blog-single-space max-w900 ml-auto mr-auto">
                {/* BLOG START */}
                <div className="blog-post blog-detail text-black">
                <div className="sx-post-title m-t15">
                    <h3 className="post-title">
                      {lang === "vi" ? title_vi : title_en}
                    </h3>
                  </div>
                  <div className="sx-post-meta">
                    <ul>
                      <li className="post-date time-publish">
                        {LANG.timePublish[lang]}
                        {timePublish}
                      </li>
                      <li className="post-category">
                        {this.renderTempItem(cusCategories, lang)}
                      </li>
                    </ul>
                  </div>
                  <div className="sx-post-media">
                    <div className="portfolio-item">
                      <img
                        className="img-responsive w-full"
                        src={reviewImg}
                        alt=""
                      />
                    </div>
                  </div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: lang === "vi" ? content_vi : content_en,
                    }}
                    className="m-t20 content-news"
                  ></div>
                  {video && video.length ? (
                    <div className="video-news m-b10">
                      {this.renderTempItem2(video)}
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="post-author2">
                    <span>{LANG.author[lang]}</span>
                    {author}
                  </div>
                </div>
                {/* OUR BLOG START */}
                {/* TITLE START */}
                <div
                  className={`section-head ${
                    latestArticle && latestArticle.length ? "" : "nonevalue"
                  }`}
                >
                  <div className="sx-separator-outer separator-left">
                    <div className="sx-separator">
                      <h3 className="sep-line-one">{LANG.latest[lang]}</h3>
                    </div>
                  </div>
                </div>
                {/* TITLE END */}
                {/* IMAGE CAROUSEL START */}
                <div
                  className={`section-content ${
                    latestArticle && latestArticle.length ? "" : "nonevalue"
                  }`}
                >
                  <div className="row">
                    {latestArticle.map((item, index) => (
                      <div
                        className="masonry-item  col-lg-4 col-md-6 col-sm-12 m-b15"
                        key={index}
                      >
                        <a href={`/news/${item.id}`}>
                          <div className="blog-post blog-grid date-style-2 cus-ar-bd">
                            <div className="sx-post-media sx-img-effect img-reflection cus-thumb">
                              <img src={item.reviewImg} alt="" />
                            </div>
                            <div className="sx-post-info p-t15 p-l10 p-r10 p-b10">
                              <div className="sx-post-meta ">
                                <ul>
                                  <li className="post-date">
                                    <strong>{item.day}</strong>{" "}
                                    <span>{item.month}</span>{" "}
                                  </li>
                                  <li className="post-author cus-author">
                                    <span>{item.author || ""}</span>
                                  </li>
                                  <li className="post-comment">
                                    {" "}
                                    {item.comments}
                                  </li>
                                </ul>
                              </div>
                              <div className="sx-post-title">
                                <h4
                                  style={{ minHeight: "53px" }}
                                  className="post-title row2lines"
                                >
                                  {item[`title_${lang}`]}
                                </h4>
                              </div>
                              {this.renderTempItem(item.cusCategories, lang)}
                              <div className="render-i"></div>
                              <div></div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <BlogSingle {...props} navigate={navigate} />;
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(WithNavigate)
);
