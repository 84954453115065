import React from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var options = {
  loop: false,
  autoplay: false,
  margin: 60,
  nav: true,
  dots: false,
  items: 2,
  responsive: {
    0: {
      items: 1,
    },
    991: {
      items: 2,
    },
  },
};
var options2 = {
  ...options,
  center: true,
};

var AnGiangTheatre = require("./../../images/gallery/AnGiangTheatre.jpeg");
var KingCrownInfinityNight = require("./../../images/gallery/KingCrownInfinityNight.jpg");
var KingCrown = require("./../../images/gallery/KingCrown.jpg");
var Certificate1 = [
  require("./../../images/gallery/Certificate01.jpg"),
  require("./../../images/gallery/Certificate02.jpg"),
];
var Certificate2 = [
  require("./../../images/gallery/Certificate03.jpg"),
  require("./../../images/gallery/Certificate04.jpg"),
];
var Certificate3 = [require("./../../images/gallery/Certificate05.jpg")];
class ProfileDetail extends React.Component {
  render() {
    const lang = this.props.lang;

    return (
      <>
        <div
          id="people"
          className="section-full p-t80 p-b50 mobile-page-padding"
        >
          <div className="container profile-page">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator">
                  <h3 className="sep-line-one">{LANG.title1[lang]}</h3>
                </div>
              </div>
            </div>

            <div className="section-content basic-color-text">
              <div className="core-lib">
                <img
                  // className="grayimg"
                  src={AnGiangTheatre}
                  alt="TheFive - Nhà hát An Giang"
                />
                <p>{CORE.theatre[lang]}</p>
              </div>

              <b className="tt-intro">{TITLE.intro[lang]}</b>
              <div className="introduce-text m-t30">
                <p
                  dangerouslySetInnerHTML={{ __html: INTRO.introduce[lang] }}
                ></p>
              </div>
              <br />
              <b className="tt-intro">{TITLE.infor[lang]}</b>
              <div className="introduce-text vb-long">
                <div
                  className="bd-vb m-t20"
                  dangerouslySetInnerHTML={{ __html: VB.vb1[lang] }}
                ></div>
                <div
                  className="bd-vb"
                  dangerouslySetInnerHTML={{ __html: VB.vb2[lang] }}
                ></div>
                <div
                  className="bd-vb"
                  dangerouslySetInnerHTML={{ __html: VB.vb3[lang] }}
                ></div>
                <div
                  className="bd-vb"
                  dangerouslySetInnerHTML={{ __html: VB.vb4[lang] }}
                ></div>
                <div
                  className="bd-vb bdb-vb m-b30"
                  dangerouslySetInnerHTML={{ __html: VB.vb5[lang] }}
                ></div>
              </div>
            </div>
            <br />
            <b className="tt-intro">{TITLE.certi[lang]}</b>
            <div className="list-awards m-t30 m-b30">
              <OwlCarousel
                className="owl-carousel owl-btn-vertical-center m-t20"
                {...options}
              >
                {Certificate1.map((item, index) => (
                  <div className="item" key={index}>
                    <img src={item} alt="" />
                  </div>
                ))}
              </OwlCarousel>
              <br />
              <OwlCarousel
                className="owl-carousel owl-btn-vertical-center m-t20"
                {...options}
              >
                {Certificate2.map((item, index) => (
                  <div className="item" key={index}>
                    <img src={item} alt="" />
                  </div>
                ))}
              </OwlCarousel>
              <br />
              <OwlCarousel
                className="owl-carousel owl-btn-vertical-center m-t20"
                {...options2}
              >
                {Certificate3.map((item, index) => (
                  <div className="item" key={index}>
                    <img src={item} alt="" />
                  </div>
                ))}
              </OwlCarousel>
              {/* <div className="core-lib m-t10 p-t15 w-100">
                <img src={Nexus3} alt="" />
                <p>Nexus-Tower 3</p>
              </div> */}
            </div>
            <br />
            <br />

            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator">
                  <h3 className="sep-line-one">{LANG.title2[lang]}</h3>
                </div>
              </div>
            </div>

            <div className="section-content">
              <p>{CORE.describe[lang]}</p>
              <div className="core-lib">
                <img
                  src={KingCrownInfinityNight}
                  alt="TheFive - Kingcrown Infinity"
                />
                <p>{CORE.kingcrowninfinity[lang]}</p>
              </div>
            </div>
            <br />
            <br />

            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator">
                  <h3 className="sep-line-one">{LANG.title3[lang]}</h3>
                </div>
              </div>
            </div>

            <div className="section-content">
              <div className="introduce-text m-t30">
                <p dangerouslySetInnerHTML={{ __html: VISION[lang] }}></p>
              </div>
              <div className="core-lib">
                <img src={KingCrown} alt="TheFive - King Crown Riverside" />
                <p>{VISION.building}</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
var LANG = {
  title1: {
    vi: "Giới thiệu, chứng nhận hoạt động và năng lực",
    en: "Introduction, certificate of activity and competence",
  },
  title2: {
    vi: "Giá trị cốt lõi",
    en: "Core values",
  },
  title3: {
    vi: "Tầm nhìn",
    en: "Vision",
  },
};
var TITLE = {
  intro: {
    vi: "Giới thiệu",
    en: "Introduction",
  },
  infor: {
    vi: "Thông tin chung",
    en: "General information",
  },
  certi: {
    vi: "Chứng nhận hoạt động và năng lực",
    en: "Certificate of activity and competence",
  },
};
var INTRO = {
  introduce: {
    vi: `•  <span>The Five & Partners</span> được thành lập bởi 5 giảng viên, giáo sư từ trường Đại học Kiến trúc thành phố Hồ Chí Minh từ 21/10/2009, là nơi hội tụ các kiến trúc sư – kỹ sư dày dạn kinh nghiệm trong lĩnh vực tư vấn thiết kế.<br /><br />
    •  <span>The Five & Partners</span> trong thế kỷ mới là sự nâng tầm của những kinh nghiệm tư vấn thiết kế đầy tính sáng tạo; đột phá về thẩm mỹ và công nghệ xây dựng ; hợp lý về công năng và kinh tế ; phù hợp với môi trường và bền vững với thời gian.<br /><br />
    •  <span>The Five & Partners</span> cung cấp dịch vụ tư vấn thiết kế với nhiều loại hình dự án : Quy hoạch khu đô thị mới , khu nông nghiệp kỹ thuật cao, khu công nghiệp tập trung ; các khu phức hợp đa chức năng và công nghệ cao ; các khu phức hợp lưu trú nghỉ dưỡng kết hợp vui chơi giải trí ; các công trình văn hóa giải trí ; các trung tâm thương mại dịch vụ , các khu nhà ở cao tầng ; các tòa nhà văn phòng ; thiết kế nội thất và cảnh quan<br /><br />
    •  <span>The Five & Partners</span> có các đối tác là các giáo sư đầu ngành đến từ các trường Đại học Nhật Bản, Hàn Quốc, Singapore ; các công ty tư vấn thiết kế có tên tuổi của Châu Âu và Bắc Mỹ đang hoạt động tại Việt Nam ; các công ty tư vấn Quốc tế chuyên ngành về thiết kế mặt dựng và chiếu sáng nghệ thuật , quản lý vận hành tòa nhà thông minh , quản lý đô thị thông minh, quản lý vận hành các công trình du lịch nghĩ dưỡng, các công ty thiết kế Công viên giải trí chuyên đề, các thương hiệu quốc tế về vật liệu xây dựng công nghệ mới , các tập đoàn quản lý vận hành khách sạn – resort, các tập đoàn tiếp thị và phân phối bất động sản.<br /><br />
    •  <span>The Five & Partners</span> có thể thay mặt, hoặc kết hợp với chủ đầu tư tổ chức các cuộc thi tuyển chọn phương án thiết kế mọi thể loại công trình kiến trúc, cũng như các cuộc thi tìm ý tưởng về quy hoạch và thiết kế đô thị.<br /><br />
    •  <span>The Five & Partners</span> đã và sẽ luôn là đối tác tin cậy của các chủ đầu tư , các tập đoàn phát triển bất động sản , các Sở ban ngành và chính quyền địa phương.<br /><br />`,
    en: `•  <span>The Five & Partners</span> was founded by five lecturers and professors from Ho Chi Minh City University of Architecture since October 21, 2009; an organization comprised by talented and experienced architects and engineers.<br /><br />
    •  <span>The Five & Partners</span> in the new era is in attempting to achieve the enhancement of innovative design consulting experiences; breakthroughs in aesthetics and construc- tion technology; come up with reasonable ideas in terms of function and economy environmentally friendly and sustainable over time.<br /><br />
    •  <span>The Five & Partners</span> provides design consulting services with various types of projects: New metropolitan area Urban Planning, High-tech Agricultural Zones, Industrial Area; High-tech and Multifunctional Complexes; Accommodation and Recreational Complex- es ; Cultural Recreation Building; Service Trading Centre, Residential High-rise Build- ing; Office Building; Interior and Landscape design.<br /><br />
    •  <span>The Five & Partners</span> cooperates actively with renowned professors from Japanese and Korean Universities, well-known design consulting firms from Europe and North America establishing representative offices in Vietnam; International consulting firms in the fields of Facade design, Art Lighting, Smart Building Management, Smart urban Management, Resort Management; Theme and Amusement Park design; International Vendors of new technology materials, Hotels and Resorts Management Group, and Real Estate Marketing and Distribution companies.<br /><br />
    •  <span>The Five & Partners</span> is pleased to act as the investor’s representative for design com- petitions in a variety of fields such as architecture and urban planning.<br /><br />
    •  <span>The Five & Partners</span> has been and continuously will be a trustworthy partner for inves- tors, real estate development corporations, departments, and local authorities.<br /><br />`,
  },
};
var CORE = {
  describe: {
    vi: "Các sản phẩm tư vấn mang tính tiên phong và khác biệt dựa trên quy trình nghiên cứu và sáng tạo trung thực phù hợp với xu hướng thiết kế của thời đại và sự phát triển của khoa học công nghệ. Do đó sản phẩm tư vấn đạt được giá trị sáng tạo cao.",
    en: "Our consulting services are pioneering and differentiated based on our honest research and creativity process which suit the contemporary design and technological advancement, as such our services carry a high level of creativity.",
  },
  theatre: {
    vi: "Nhà hát tỉnh An Giang",
    en: "An Giang Theatre",
  },
  kingcrowninfinity: {
    vi: "Kingcrown Infinity",
    en: "Kingcrown Infinity",
  },
};
var VB = {
  vb1: {
    vi: `<p>Tên đơn vị: <span>CÔNG TY CỔ PHẦN TƯ VẤN ĐẦU TƯ XÂY DỰNG NĂM VÀ CỘNG SỰ</span></p><br /><p>Tên giao dịch: <span>THE FIVE AND PARTNERS</span></p>`,
    en: `<p>Company name: <span>THE FIVE AND PARTNERS CONSTRUCTION INVESTMENT AND CONSULTANT JOINT STOCK COMPANY</span></p>`,
  },
  vb2: {
    vi: `<p>Trụ sở chính : <span>316 Lê Văn Sỹ, Phường 1, Quận Tân Bình, Tp.Hồ Chí Minh, Việt Nam</span></p>
    <p>Điện thoại : <span>0238.469.861</span></p>
    <p>Văn phòng giao dịch : <span>(Lầu 2) 98 Trần Quang Khải, Phường Tân Định, Quận 1, Hồ Chí Minh</span></p>
    <p>Tài khoản : <span>037 100 389 7952 Ngân hàng TMCP Ngoại thương Việt Nam – CN Tân Định</span></p>`,
    en: `<p>Headquaters : <span>316 Le Van Sy Street, Ward 1, Tan Binh District, Ho Chi Minh City, VietNam</span></p>
    <p>Tel : <span>+84238.469.861</span></p>
    <p>Office : <span>(2nd Floor) 98 Tran Quang Khai Street, Tan Dinh Ward, District 1, Ho Chi Minh</span></p>
    <p>Bank account : <span>037 100 389 7952 Joint Stock Commercial Bank for Foreign Trade of Vietnam - Tan Dinh Branch</span></p>`,
  },
  vb3: {
    vi: `<p>Tổng giám đốc : <span>LÊ HỒNG QUANG</span></p>`,
    en: `<p>General Director : <span>LE HONG QUANG</span></p>`,
  },
  vb4: {
    vi: `<p>Mã số thuế : <span>0309528814</span></p>
    <p>Giấy chứng nhận đăng ký kinh doanh số 0309528814 do Sở Kế hoạch Đầu Tư TP.Hồ Chí Minh cấp</p>
    <p>Đăng ký lần đầu ngày 21 tháng 10 năm 2009</p>
    <p>Đăng ký thay đổi lần thứ 14, ngày 12 tháng 05 năm 2023</p>`,
    en: `<p>Tax Code : <span>0309528814</span></p>
    <p>Business registration certificate No. 0309528814 issued by Ho Chi Minh City Department of Planning and Investment</p> 
    <p>Registered for the first time on October 21 2009</p> 
    <p>Registered for the 14th time on May 12, 2023</p>`,
  },
  vb5: {
    vi: `<p>Các ngành nghề kinh doanh chính :</p>
    <p>•  Thiết kế kiến trúc công trình dân dụng & công nghiệp</p>
    <p>•  Thiết kế quy hoạch xây dựng</p>
    <p>•  Thiết kế và thi công trang trí nội – ngoại thất</p>
    <p>•  Thiết kế và thi công cảnh quan cây xanh</p>
    <p>•  Tư vấn quản lý dự án</p>
    <p>•  Thiết kế xây dựng công trình hạ tầng kỹ thuật</p>
    <p>•  Hoạt động tư vấn đầu tư ( trừ tư vấn kế toán, tài chính, pháp lý)</p>
    <p>•  Tư vấn đấu thầu</p>
    <p>•  Quản lý dự án đầu tư xây dựng công trình</p>
    <p>•  Kiểm định chất lượng công trình xây dựng.</p>
    <p>•  Lập dự án đầu tư</p>
    <p>•  Thẩm tra thiết kế kiến trúc công trình dân dụng và công nghiệp</p>
    <p>•  Thẩm tra thiết kế quy hoạch xây dựng</p>
    <p>•  Thẩm tra thiết kế xây dựng công trình hạ tầng kỹ thuật</p>
    <p>•  Kinh doanh thiết bị - vật liệu xây dựng, nội thất</p>
    <p>•  Xây dựng công trình dân dụng, công nghiệp</p>
    <p>•  Tư vấn, tổ chức các cuộc thi tuyển chọn phương án thiết kế: ý tưởng thiết kế công trình kiến trúc; ý tưởng quy hoạch và thiết kế đô thị</p>`,
    en: `<p>Prime professions:</p>
    <p>•  Architectural design of Civil & Industrial Projects</p>
    <p>•  Construction Planning Design</p>
    <p>•  Interior - Exterior Design & Construction</p>
    <p>•  Landscape Design & Construction</p>
    <p>•  Project management Consultancy</p>
    <p>•  Technical infrastructure Projects Design & Construction</p>
    <p>•  Investment Consulting Activities (except Accounting, Financial and Legal Consulting)</p>
    <p>•  Bidding Consultancy</p>
    <p>•  Construction Investment project management</p>
    <p>•  Quality inspection of construction works</p>
    <p>•  Establish investment projects</p>
    <p>•  Verification architectural designs of civil and industrial projects.</p>
    <p>•  Verification of construction planning design</p>
    <p>•  Verification the design and construction of technical infrastructure works</p>
    <p>•  Trading in equipment - building materials, furniture</p>
    <p>•  Civil & Industrial Construction</p>
    <p>•  Consulting, organizing contests to select de- sign proposals: architectural design concept; urban planning and design concept.</p>`,
  },
};
var VISION = {
  vi: `<p>•  <span>The Five & Partners</span> đi đầu trong nghiên cứu các xu hướng mới của thiết kế Quy hoạch và thiết kế Kiến trúc, nâng cao tư duy sáng tạo. Trong bối cảnh toàn cầu hóa và đề cao tính bản địa, sản phẩm của chúng tôi mang phong cách kiến trúc đương đại kết hợp với tinh hoa văn hóa Việt Nam, bảo vệ và gìn giữ môi trường. Kết quả các nghiên cứu và ấn phẩm của chúng tôi tiết lộ những câu chuyện về tương lai của thiết kế.</p>
  <p>•  Bên cạnh đó , chúng tôi còn tham gia nghiên cứu khoa học phục vụ giảng dạy kiến trúc, tổ chức các hội thảo chuyên ngành , các hoạt động giao lưu kiến trúc trong và ngoài nước.</p>`,
  en: `<p>•  <span>The Five & Partners</span> takes the lead in researching new trends in planning and architectural design, thereby enhancing creative thinking. Our works, in the context of globalization and vernacular architecture appreciation, denote a characteristically contemporary style interweaving with Vietnamese cultural quintessence, protecting and preserving the environment. Our studies and publications reveal the stories of design’s future.</p>
  <p>•  Besides, we are also actively involved in architectural research, teaching and organize seminars on architectural exchange activities locally as well as internationally.</p>`,
  building: "King Crown Riverside",
};

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(ProfileDetail);
