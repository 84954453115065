import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home1 from "./Pages/Home1";

import About1 from "./Pages/Profile";

// import FontIcons from './Pages/FontIcons';
import Error from "./Pages/Error";

import ProjectGrid3 from "./Pages/ProjectGrid3";

import ProjectDetail1 from "./Pages/ProjectDetail1";

import BlogGrid from "./Pages/BlogGrid";

import BlogSingle from "./Pages/BlogSingle";

import ContactUs from "./Pages/ContactUs";
import ScrollToTop from "./Common/ScrollToTop";
import Profile from "./Pages/Profile";
import Awards from "./Pages/Awards";
import Partners from "./Pages/Partners";
import OurPeople from "./Pages/OurPeople";
import NewCareers from "./Pages/NewCareers";
import { basicAPI } from "./data/articles.js";
import { connect } from "react-redux";

class Components extends React.Component {
  async componentDidMount() {
    setTimeout(async () => {
      const data = await basicAPI.getListProject();
      if (data && data.projects) {
        this.props.setProjectList(data.projects);
      }
    }, 2000);
  }
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/project" element={<ProjectGrid3 />} />
            <Route path="/project/:file_id" element={<ProjectDetail1 />} />
            <Route
              path="/profile"
              element={<Profile title={"Profile"} pagename={"Profile"} />}
            />
            {/* <Route path='/people' element={<Profile title={"People"} pagename={"People"}/>} /> */}
            <Route path="/people" element={<OurPeople />} />
            <Route path="/awards" element={<Awards />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/careers" element={<NewCareers />} />
            <Route path="/" element={<Home1 />} />
            <Route path="/about" element={<About1 />} />

            {/* <Route path='/icon-font' element={<FontIcons/>} /> */}
            {/* <Route path='/error-404' element={<Error/>} /> */}

            <Route path="/project-detail1" element={<ProjectDetail1 />} />

            <Route path="/news" element={<BlogGrid />} />
            <Route path="/news/:news_id" element={<BlogSingle />} />

            <Route path="/contact-us" element={<ContactUs />} />

            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
    value: state.value,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setProjectList: (data) => {
      dispatch({ type: "project_list", value: data });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Components);
