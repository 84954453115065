import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import PartnersList from "./../Elements/Partners";

class Partners extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <Header />
        <PartnersList />

        <Footer />
      </>
    );
  }
}

export default Partners;
