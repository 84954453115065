import React from "react";
import Components from "./components/Components";
import ScrolToTop from "./components/Elements/ScrolToTop";
import Loader from "./components/Elements/Loader";

class App extends React.Component {
  componentDidMount() {
    const loadingPage = document.getElementById("loading-page");
    if (!loadingPage) return;
    setTimeout(() => {
      if (window && window.jQuery) {
        window.jQuery(loadingPage).fadeOut(1000);
      } else {
        loadingPage.style.display = "none";
      }
    }, 1000);
  }
  render() {
    return (
      <div className="App">
        <Components />
        <ScrolToTop />
        <Loader />
      </div>
    );
  }
}

export default App;
