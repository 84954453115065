import React from "react";
import { NavLink } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import { ApiFilter } from "../data/dataProject";
import { basicAPI } from "../data/articles.js";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { projectList } from "../../common/lang";
import FlipMove from "react-flip-move";

var bgThumb = require("./../../images/background/bg_projects.jpeg");

const withRouter = (Component) => (props) => {
  const history = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (
    <Component
      params={params}
      history={history}
      location={location}
      {...props}
    />
  );
};

const LANG = {
  all: {
    vi: "Tất cả",
    en: "All",
  },
  load: {
    vi: "Xem Thêm",
    en: "Load More",
  },
  title: {
    vi: "Dự án",
    en: "Projects",
  },
  pagename: {
    vi: "Dự án",
    en: "Projects",
  },
};

const fixedItem = 5;
class ProjectGrid3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      active: "",
    };
  }
  async componentDidMount() {
    let category = "all";
    const tempFilter = localStorage.getItem("valueProject");
    if (tempFilter && tempFilter.length) {
      category = tempFilter;
    }
    if (category && category[0] === "#") {
      category = category.substring(1);
    }
    this.props.SetLoading(false);
    setTimeout(() => {
      this.setActiveFilter("all");
      this.setState({
        active: category,
      });
      this.setActiveFilter(category);
      setTimeout(() => {
        const scrollFilter = document.getElementById("filterlist");
        if (scrollFilter) {
          scrollFilter.scrollIntoView({ behavior: "smooth" });
        }
      }, 200);
    }, 500);
  }
  componentWillUnmount() {
    this.setState({ projects: [], active: "" });
    this.props.SetLoading(true);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.value && nextProps.value[0] === "#") {
      if (nextProps.value !== "#" + this.state.active) {
        this.setState({ active: nextProps.value.substring(1) });
      }
    }
  }
  handleCheckLang(name, lang) {
    const find = projectList.find((i) => i.text === name);
    if (find && find.text) {
      return find.name[lang];
    }
    return name;
  }
  setActiveFilter(value) {
    localStorage.setItem("valueProject", "#" + value);
    this.props.ActiveProjectType("#" + value);
  }
  handleFilterProject(projects, active) {
    if (active === "all") return projects;
    return projects.filter((item) => item.filter.includes(active));
  }

  render() {
    const lang = this.props.lang;
    const project_list = this.props.project_list;
    const loading = this.props.loading;
    const active = this.state.active;
    return (
      <>
        <Header />
        <div className="">
          <Banner
            title={LANG.title[lang]}
            pagename={LANG.pagename[lang]}
            lang={lang}
            description=""
            bgimage={bgThumb}
            isHideContent={true}
            classname={"grid-project-thumb"}
          />
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding fullscreen-project">
            <div className="">
              {/* Filter Nav START */}
              <div className="filter-wrap p-b30 text-center">
                <ul
                  style={{ scrollMargin: "100px", width: "auto" }}
                  id="filterlist"
                  className="filter-navigation"
                >
                  <li
                    className={active === "all" ? "active" : ""}
                    onClick={() => this.setActiveFilter("all")}
                  >
                    <a className="btn from-top">{LANG.all[lang]}</a>
                  </li>
                  {ApiFilter.filters.map((item, index) => (
                    <li
                      key={index}
                      className={active === item.id ? "active" : ""}
                      onClick={() => this.setActiveFilter(item.id)}
                    >
                      <a className="btn from-top">
                        {this.handleCheckLang(item.label, lang)}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mh-70" key={loading}>
                <FlipMove
                  className="masonry-outer work-grid row list-unstyled"
                  typeName="ul"
                  enterAnimation="fade"
                  leaveAnimation="fade"
                  maintainContainerHeight
                >
                  {this.handleFilterProject(project_list, active).map(
                    (item, index) => {
                      return (
                        <li
                          key={item.id + active}
                          className={`masonry-item  col-lg-4 col-md-6 col-mdw-full-6 col-sm-12 m-b30`}
                        >
                          <a
                            className="w-full cus-card-pr"
                            href={`/project/${item.id}`}
                          >
                            <div>
                              <div className="sx-box image-hover-block w-full">
                                <div
                                  className="newbg-project"
                                  style={{
                                    backgroundImage: `url(${item.thumbnail})`,
                                  }}
                                ></div>
                                <div className="sx-info  p-t20 text-white">
                                  <h4 className="sx-tilte"></h4>
                                </div>
                                <a className="mfp-link" href={item.thumbnail}>
                                  <i className="mfp-link fa fa-arrows-alt" />
                                </a>
                              </div>
                              <span className="name-project m-b0">
                                {item[`name_${lang}`] || ""}
                              </span>
                            </div>
                            <span className="location-project m-b0 row1lines">
                              {item[`location_${lang}`] || ""}
                            </span>
                          </a>
                        </li>
                      );
                    }
                  )}
                </FlipMove>
              </div>
              {/* )} */}
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
    value: state.value,
    project_list: state.project_list,
    loading: state.loading,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    ActiveProjectType: (value) => {
      dispatch({ type: "project", value: value });
    },
    SetLoading: (value) => {
      dispatch({ type: "loading", value: value });
    },
  };
};

export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(ProjectGrid3)
);
