import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";
import { connect } from "react-redux";

const LANG = {
  infor: {
    vi: "© 2022 Công ty DSA. Được thiết kế bởi DSA.",
    en: "© 2022 DSA Company. Designed By DSA.",
  },
  contactus: {
    vi: "Liên hệ chúng tôi",
    en: "Contact Us",
  },
  add: {
    vi: "Địa chỉ: Lầu 2, 98 Trần Quang Khải, Phường Tân Định, Quận 1, TP.HCM",
    en: "Address: Floor 2nd, 98 Tran Quang Khai Street, Tan Dinh Ward, District 1, HCMC",
  },
  phone: {
    vi: "Điện thoại",
    en: "Tel",
  },
};

class Footer extends React.Component {
  render() {
    const lang = this.props.lang;
    return (
      <>
        <footer className="site-footer footer-large  footer-dark	footer-wide">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-4 col-md-5 col-sm-5 ">
                  <div className="widget widget_about">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15 relative">
                      <NavLink to={"/"}>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUUAAAB4CAYAAABl5+CaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAitJREFUeNrs14GNhCAURVEwtGZBWogFaXGYWIGagCDnNLCzznr3/RiWPYfC8jbH0Ii4HrnGz3nyO9f4TD1/ntb4vv79/KcAgCgyxmIHUWTo0xZEEWsRRBFrEUQRQBRxQoMo4oQGUcRaBFHEWgRRBBBFcEIjijihQRTBWkQUsRZBFAFEEZzQiCI4oRFFsBYRRbAWEUUARBEnNIgiTmgQRaxFuCVZObx9jqNGy9/Qv5+/pQggijihQRRxRoIoYi2CKGItgigCiCJOaBBFnNAgiliL1iKiiLUIogggiuCERhRxQoMogrWIKGItgigCiCI4oRFFcEIjimAtIopgLSKKAKIITmjGk7xEzseSz0C0/KOyFMFLiCiCxYwoAogiOKERRXBCI4pgLSKKYC0iigCIIk5oEEWc0CCKWIsgiliLIIoAoghOaEQRJzSIIliLiCLWIogigCiCExpRxAkNogjWIqII1iKiCCCK4ISmT8m5RgvfiWh5xyxFsBYRRbDgEUUAUQQnNKIITmhEEaxFRBGsRUQRQBTBCY0oghMaUQRrEVH0CLAWQRQBRBGc0IgiTmgQRbAWEUWsRRBFAFEEJzSiiBMaRBGsRUQRrEW+kjwCwKK3FMEJjSjihAZRBGsRUcRaBFEEEEVwQiOKOKFBFMFaRBTBWkQUAUQRnNCIIjihEUWwFhFFsBYRRQBRBCc0oghOaEQRrEVEEaxFRBFAFAEntCiCExpRBKxFLqcAAwANKfFPYSzV3gAAAABJRU5ErkJggg=="
                          alt="The Five"
                        />
                      </NavLink>
                      <span className="absolute copyrights-textv2">
                        {LANG.infor[lang]}
                      </span>
                    </div>

                    {/*
                                        <p>This website for interior and architecture purpose. Today we can tell you, thanks to your passion.</p>
                                        <ul className="social-icons  sx-social-links">
                                            <li><a href="https://www.behance.net/" className="fa fa-behance" target="_blank"></a></li>
                                            <li><a href="https://www.facebook.com" className="fa fa-facebook" target="_blank"></a></li>
                                            <li><a href="https://twitter.com" className="fa fa-twitter" target="_blank"></a></li>
                                            <li><a href="https://www.instagram.com" className="fa fa-instagram" target="_blank"></a></li>
                                        </ul>
                                        */}
                  </div>
                </div>
                {/* RESENT POST */}
                {/*
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget recent-posts-entry-date">
                                        <h5 className="widget-title">Resent Post</h5>
                                        <div className="widget-post-bx">
                                            <div className="widget-post clearfix">
                                                <div className="sx-post-date text-center text-uppercase text-white">
                                                    <strong className="p-date">15</strong>
                                                    <span className="p-month">Sep</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="sx-post-info">
                                                    <div className="sx-post-header">
                                                        <h6 className="post-title"><NavLink to={"/blog-single"}>On these beams, we’re.</NavLink></h6>
                                                    </div>
                                                    <div className="sx-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 28</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-post clearfix">
                                                <div className="sx-post-date text-center text-uppercase text-white">
                                                    <strong className="p-date">17</strong>
                                                    <span className="p-month">Sep</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="sx-post-info">
                                                    <div className="sx-post-header">
                                                        <h6 className="post-title"><NavLink to={"/blog-single"}>We’ll be a sensation for</NavLink></h6>
                                                    </div>
                                                    <div className="sx-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 29</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="widget-post clearfix">
                                                <div className="sx-post-date text-center text-uppercase text-white">
                                                    <strong className="p-date">18</strong>
                                                    <span className="p-month">Sep</span>
                                                    <span className="p-year">2022</span>
                                                </div>
                                                <div className="sx-post-info">
                                                    <div className="sx-post-header">
                                                        <h6 className="post-title"><NavLink to={"/blog-single"}>We’ll be a sensation for</NavLink></h6>
                                                    </div>
                                                    <div className="sx-post-meta">
                                                        <ul>
                                                            <li className="post-author"><i className="fa fa-user" />By Admin</li>
                                                            <li className="post-comment"><i className="fa fa-comments" /> 29</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    */}
                {/* USEFUL LINKS */}
                {/*
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Useful links</h5>
                                        <ul>
                                            <li><NavLink to={"/about-1"}>About</NavLink></li>
                                            <li><NavLink to={"/services-1"}>Services</NavLink></li>
                                            <li><NavLink to={"/project-grid-3-columns"}>Projects</NavLink></li>
                                            <li><NavLink to={"/blog-grid"}>Blog</NavLink></li>
                                            <li><NavLink to={"/contact-us"}>Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                */}
                {/* CONTACT US */}
                <div className="col-lg-4 col-md-5 col-sm-5">
                  <div className=" widget_address_outer">
                    <h5 className="widget-title">{LANG.contactus[lang]}</h5>
                    <ul className="widget_address">
                      <li>{LANG.add[lang]}</li>
                      <li>
                        Email:{" "}
                        <a
                          style={{ color: "#fff" }}
                          href="mailto:FiveandPartners@thefive.com.vn"
                        >
                          FiveandPartners@thefive.com.vn
                        </a>
                      </li>
                      <li>
                        {LANG.phone[lang]}:{" "}
                        <a style={{ color: "#fff" }} href="tel:+842838469861">
                          +8428 3846 9861
                        </a>
                      </li>
                      {/* <li>(+298) 146-6543-480</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          {/* <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="sx-footer-bot-left">
                                    <span className="copyrights-text">{LANG.infor[lang]}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </footer>
        <Switcher />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Footer);
