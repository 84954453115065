import React from "react";
import { connect } from "react-redux";

const LANG = {
  expertise: {
    vi: "Chuyên môn",
    en: "Expertise",
  },
  readmore: {
    vi: "XEM THÊM",
    en: "READ MORE",
  },
};
const expertises = [
  {
    count: "01",
    description: {
      vi: "Thiết kế công trình dân dụng và công nghiệp",
      en: "Architectural design of Civil & Industrial Projects",
    },
    bg: require("./../../images/expertise/01-crop.jpg"),
  },
  {
    count: "02",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/02-crop.jpg"),
    description: {
      vi: "Thiết kế quy hoạch và xây dựng",
      en: "Construction Planning Design",
    },
  },
  {
    count: "03",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/03-crop.jpg"),
    description: {
      vi: "Thiết kế và thi công trang trí nội ngoại thất",
      en: "Interior - Exterior Design & Construction",
    },
  },
  {
    count: "04",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/04-crop.jpg"),
    description: {
      vi: "Thiết kế và thi công cảnh quan cây xanh",
      en: "Landscape Design & Construction",
    },
  },
  {
    count: "05",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/05-crop.jpg"),
    description: {
      vi: "Tư vấn quản lý dự án",
      en: "Project management Consultancy",
    },
  },
  {
    count: "06",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/06-crop.jpg"),
    description: {
      vi: "Thiết kế xây dựng công trình hạ tầng kỹ thuật",
      en: "Technical infrastructure Projects Design & Construction",
    },
  },
  {
    count: "07",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/07-crop.jpg"),
    description: {
      vi: "Hoạt động tư vấn đầu tư (trừ tư vấn kế toán, tài chính, pháp lý)",
      en: "Investment Consulting Activities (except Accounting, Financial and Legal Consulting)",
    },
  },
  {
    count: "08",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/08-crop.jpg"),
    description: {
      vi: "Tư vấn đấu thầu",
      en: "Bidding Consultancy",
    },
  },
  {
    count: "09",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/09-crop.jpg"),
    description: {
      vi: "Quản lý dự án đầu tư xây dựng công trình",
      en: "Construction Investment project management",
    },
  },
  {
    count: "10",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/10-crop.jpg"),
    description: {
      vi: "Kiểm định chất lượng công trình xây dựng",
      en: "Quality inspection of construction works",
    },
  },
  {
    count: "11",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/11-crop.jpg"),
    description: {
      vi: "Lập dự án đầu tư",
      en: "Establish investment projects",
    },
  },
  {
    count: "12",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/12-crop.jpg"),
    description: {
      vi: "Thẩm tra thiết kế kiến trúc công trình dân dụng và công nghiệp",
      en: "Verification architectural designs of civil and industrial projects",
    },
  },
  {
    count: "13",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/13-crop.jpg"),
    description: {
      vi: "Thẩm tra thiết kế quy hoạch xây dựng",
      en: "Verification of construction planning design",
    },
  },
  {
    count: "14",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/14-crop.jpg"),
    description: {
      vi: "Thẩm tra thiết kế xây dựng công trình hạ tầng kỹ thuật",
      en: "Verification the design and construction of technical infrastructure works",
    },
  },
  {
    count: "15",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/15-crop.jpg"),
    description: {
      vi: "Kinh doanh thiết bị - vật liệu xây dựng, nội thất",
      en: "Trading in equipment - building materials, furniture",
    },
  },
  {
    count: "16",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/16-crop.jpg"),
    description: {
      vi: "Xây dựng công trình dân dụng, công nghiệp",
      en: "Civil & Industrial Construction",
    },
  },
  {
    count: "17",
    title: {
      vi: "Kế hoạch và dự án",
      en: "Plans and Projects",
    },
    bg: require("./../../images/expertise/17-crop.jpg"),
    description: {
      vi: "Tư vấn, tổ chức các cuộc thi tuyển chọn phương án thiết kế: ý tưởng thiết kế công trình kiến trúc; ý tưởng quy hoạch và thiết kế đô thị",
      en: "Consulting, organizing contests to select design proposals: architectural design concept; urban planning and design concept",
    },
  },
];

class Services1 extends React.Component {
  render() {
    const lang = this.props.lang;
    return (
      <>
        <div
          id="expertise"
          style={{ scrollMargin: "50px" }}
          className="section-full mobile-page-padding p-t80  p-b50 bg-gray"
        >
          <div className="section-content">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div className="sx-separator bg-moving bg-repeat-x">
                    <h3 className="sep-line-one">{LANG.expertise[lang]}</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div className="row">
                {expertises.map((item, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6 m-b30" key={index}>
                    <div
                      className="sx-icon-box-wraper  icon-count-2-outer"
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundImage: `url(${item.bg})`,
                      }}
                    >
                      <div className="icon-count-2">
                        <span className="icon-count-number">{item.count}</span>

                        <div className="icon-content">
                          {/*<h4 className="sx-tilte">{item.title[lang]}</h4>*/}
                          <p className="row4lines">{item.description[lang]}</p>
                          {/* <div className="text-left">
                            <NavLink
                              to={"/services-detail"}
                              className="site-button-link"
                            >
                              {LANG.readmore[lang]}
                            </NavLink>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>{LANG.expertise[lang]}</strong>
          </div> */}
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Services1);
