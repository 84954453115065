import React from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { basicAPI } from "../data/articles.js";

var bnr = require("./../../images/background/bg-map.png");

const LANG = {
  contact: {
    vi: "LIÊN HỆ",
    en: "CONTACT",
  },
  submit: {
    vi: "GỬI THÔNG TIN",
    en: "SUBMIT",
  },
  phone: {
    vi: "Số điện thoại",
    en: "Phone number",
  },
  email: {
    vi: "Địa chỉ email",
    en: "Email address",
  },
  address: {
    vi: "Địa chỉ",
    en: "Address info",
  },
  add: {
    vi: "Lầu 2, 98 Trần Quang Khải, Phường Tân Định, Quận 1, TP.HCM",
    en: "Floor 2nd, 98 Tran Quang Khai Street, Tan Dinh Ward, District 1, HCMC",
  },
  SUBMIT: {
    vi: "GỬI THÔNG TIN",
    en: "SUBMIT",
  },
  SENDING: {
    vi: "ĐANG GỬI...",
    en: "SENDING...",
  },
  INFORMATION: {
    vi: "THÔNG BÁO",
    en: "INFORMATION",
  },
  form: {
    name: {
      vi: "Họ và tên",
      en: "Your name",
    },
    email: {
      vi: "Email của bạn",
      en: "Your email",
    },
    phone: {
      vi: "Số điện thoại của bạn",
      en: "Your phone",
    },
    message: {
      vi: "Vui lòng để lại lời nhắn",
      en: "Leave a message",
    },
    search: {
      vi: "Nhập để tìm kiếm",
      en: "Type to search",
    },
  },
};
class Header extends React.Component {
  constructor(props) {
    let langLocal = localStorage.getItem("lang");
    super(props);
    this.state = {
      fullname: "",
      email: "",
      phone: "",
      message: "",
      showToast: false,
      message_en: "",
      message_vi: "",
      typeToast: "",
      loading: false,
      logo: require("./../../images/logo.png"),
    };
    if (langLocal && langLocal.length) {
      if (langLocal === "en") this.props.ButtonChangeEN();
      if (langLocal === "vi") this.props.ButtonChangeVI();
    }
    this.submitForm = this.submitForm.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.checkValidate = this.checkValidate.bind(this);
  }

  state = {
    isSearchActive: false,
    isQuoteActive: true,
    showLogo: false,
  };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  onChangeInput = (event) => {
    const { value, name } = event.target;
    const temp = {};
    temp[name] = value;
    this.setState({ ...this.state, ...temp });
  };

  handleQuoteToggle = () => {
    this.setState({
      isQuoteActive: !this.state.isQuoteActive,
      fullname: "",
      email: "",
      phone: "",
      message: "",
      showToast: false,
      message_en: "",
      message_vi: "",
      typeToast: "",
      loading: false,
    });
  };
  checkValidate = (state) => {
    let tempVld = {
      showToast: true,
      message_en: "",
      message_vi: "",
      typeToast: "fail",
    };
    let trigger = false;
    if (!state.fullname) {
      tempVld.message_en = "Please fill your fullname";
      tempVld.message_vi = "Vui lòng điền họ và tên của bạn";
      trigger = true;
    }
    if (trigger === false && !state.email && !state.phone) {
      tempVld.message_en = "Please fill at least your email or phone number";
      tempVld.message_vi =
        "Vui lòng điền ít nhất email hoặc số điện thoại của bạn";
      trigger = true;
    }
    if (trigger) {
      this.setState({ ...tempVld });
      setTimeout(() => {
        this.setState({
          showToast: false,
          message_en: "",
          message_vi: "",
          typeToast: "",
        });
      }, 2000);
      return false;
    }
    return true;
  };
  async submitForm() {
    const validate = this.checkValidate({ ...this.state });
    if (!validate) return;

    this.setState({ loading: true });
    const resForm = new FormData();
    resForm.append("contact", true);
    resForm.append("fullname", this.state.fullname);
    resForm.append("email", this.state.email);
    resForm.append("phone", this.state.phone);
    resForm.append("message", this.state.message);

    const response = await basicAPI.sendmail(resForm);
    let temp = {
      showToast: true,
      message_en: "Something went wrong",
      message_vi: "Có lỗi xảy ra",
      typeToast: "fail",
    };
    if (response && response.result) {
      temp.message_en = response.message_en;
      temp.message_vi = response.message_vi;
      temp.typeToast = "success";
    }
    this.setState({ ...temp, loading: false });
    setTimeout(() => {
      const reset = {
        cvFile: null,
        fullname: "",
        email: "",
        phone: "",
        message: "",
        showToast: false,
        message_en: "",
        message_vi: "",
        typeToast: "",
        loading: false,
      };
      this.setState({ ...reset });
    }, 4000);
  }

  componentDidMount() {
    if (window.location.pathname !== "/") {
      this.setState({ showLogo: true });
    }

    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
      if (window.location.pathname === "/")
        this.setState({ showLogo: offset > 425 });
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const isSearchActive = this.state.isSearchActive;
    const isQuoteActive = this.state.isQuoteActive;
    const showLogo = this.state.showLogo;
    const lang = this.props.lang;
    const showToast = this.state.showToast;
    const typeToast = this.state.typeToast;

    return (
      <>
        <div className={`toastmail ${showToast ? typeToast : ""}`}>
          <i
            className={`toasticon fas ${
              typeToast === "success" ? "fa-check-circle" : "fa-times-circle"
            } `}
          ></i>
          <p className="toasttitle">{LANG.INFORMATION[lang]}</p>
          <p className="toastmessage">{this.state[`message_${lang}`]}</p>
        </div>

        <header className="site-header header-style-1 nav-wide mobile-sider-drawer-menu ">
          <div className="sticky-header main-bar-wraper navbar-expand-lg header-site">
            <div className="main-bar header-left-gray-block bg-white">
              <div className="container clearfix new-edit-clearfix">
                <button
                  id="mobile-side-drawer"
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggler collapsed"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar icon-bar-first" />
                  <span className="icon-bar icon-bar-two" />
                  <span className="icon-bar icon-bar-three" />
                </button>
                {/* EXTRA NAV */}
                <div className="extra-nav">
                  <div className="extra-cell mutil-lang">
                    <span
                      className={`${lang === "en" ? "active" : ""}`}
                      onClick={this.props.ButtonChangeEN}
                    >
                      EN
                    </span>
                    <span style={{ color: "gray" }}>{" / "}</span>
                    <span
                      className={`${lang === "vi" ? "active" : ""}`}
                      onClick={this.props.ButtonChangeVI}
                    >
                      VI
                    </span>
                  </div>
                  <div className="extra-cell">
                    <div className="contact-slide-show">
                      <NavLink
                        to={"#"}
                        className="get-in-touch-btn from-top"
                        onClick={this.handleQuoteToggle}
                      >
                        {LANG.contact[lang]}
                      </NavLink>
                    </div>
                  </div>
                </div>
                {/* EXTRA Nav */}
                {/* MAIN NAVIGATION */}
                <div className="header-nav nav-dark navbar-collapse collapse justify-content-start collapse">
                  <Navigation />
                </div>
                {showLogo && (
                  <div
                    className={`customlogoheader ${showLogo ? "showLogo" : ""}`}
                  >
                    <NavLink to={"/"} className="">
                      <img
                        src={require("./../../images/logo.png")}
                        alt="The Five"
                      />
                    </NavLink>
                  </div>
                )}

                {/* CONTACT */}
                <div
                  className="contact-slide-hide"
                  style={{
                    backgroundImage: "url(" + bnr + ")",
                    right: isQuoteActive ? "0px" : "100%",
                  }}
                >
                  <div className="contact-nav">
                    <NavLink
                      to={"#"}
                      className="contact_close"
                      onClick={this.handleQuoteToggle}
                    >
                      ×
                    </NavLink>
                    <div className="contact-nav-form">
                      <div className="row" style={{ marginBottom: "15px" }}>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                          <div
                            className=" contact-nav-info"
                            style={{ minHeight: "415px" }}
                          >
                            <div className="sx-icon-box-wraper left p-b30">
                              <div className="icon-xs inline-icon m-b20 scale-in-center">
                                <i className="fa fa-phone" />
                              </div>
                              <div className="icon-content">
                                <h6 className="m-t0">{LANG.phone[lang]}</h6>
                                <p>+8428 3846 9861</p>
                              </div>
                            </div>
                            <div className="sx-icon-box-wraper left p-b30">
                              <div className="icon-xs inline-icon m-b20 scale-in-center">
                                <i className="fa fa-envelope" />
                              </div>
                              <div className="icon-content">
                                <h6 className="m-t0">{LANG.email[lang]}</h6>
                                <p>FiveandPartners@thefive.com.vn</p>
                              </div>
                            </div>
                            <div className="sx-icon-box-wraper left p-b30">
                              <div className="icon-xs inline-icon m-b20 scale-in-center">
                                <i className="fa fa-map-marker" />
                              </div>
                              <div className="icon-content">
                                <h6 className="m-t0">{LANG.address[lang]}</h6>
                                <p>{LANG.add[lang]}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 ">
                          <div
                            className="contact-nav-field shadow-lg p-a30 bg-white"
                            style={{ backgroundImage: "url(" + bnr + ")" }}
                          >
                            <div className="cons-contact-form2 form-transparent">
                              <div className="input input-animate">
                                {/* <label htmlFor="name">Name</label> */}
                                <input
                                  type="text"
                                  id="name"
                                  required
                                  placeholder={LANG.form.name[lang]}
                                  name="fullname"
                                  value={this.state.fullname}
                                  onChange={this.onChangeInput}
                                  autoComplete="off"
                                />
                                <span className="spin" />
                              </div>
                              <div className="input input-animate">
                                {/* <label htmlFor="email">Email</label> */}
                                <input
                                  id="email"
                                  required
                                  placeholder={LANG.form.email[lang]}
                                  name="email"
                                  value={this.state.email}
                                  onChange={this.onChangeInput}
                                  autoComplete="off"
                                />
                                <span className="spin" />
                              </div>
                              <div className="input input-animate">
                                {/* <label htmlFor="Phone">Phone</label> */}
                                <input
                                  type="text"
                                  id="Phone"
                                  required
                                  placeholder={LANG.form.phone[lang]}
                                  name="phone"
                                  value={this.state.phone}
                                  onChange={this.onChangeInput}
                                  autoComplete="off"
                                />
                                <span className="spin" />
                              </div>
                              <div className="input input-animate">
                                {/* <label htmlFor="message">Textarea</label> */}
                                <textarea
                                  id="message"
                                  required
                                  placeholder={LANG.form.message[lang]}
                                  name="message"
                                  value={this.state.message}
                                  onChange={this.onChangeInput}
                                  autoComplete="off"
                                />
                                <span className="spin" />
                              </div>
                              <div className="text-center">
                                <button
                                  className={`button-27 m-t20 ${
                                    this.state.loading ? "loadingbtn" : ""
                                  }`}
                                  onClick={this.submitForm}
                                >
                                  {this.state.loading
                                    ? LANG.SENDING[lang]
                                    : LANG.SUBMIT[lang]}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="map-container">
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <iframe
                              title="Gmap iframe"
                              width="100%"
                              height="500"
                              id="gmap_canvas"
                              src="https://maps.google.com/maps?q=10.792474941565978,106.6929354045329&t=&z=13&ie=UTF8&iwloc=&output=embed"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              marginWidth="0"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* SITE SEARCH */}
                <div id="search" className={isSearchActive ? "open" : null}>
                  <span className="close" onClick={this.handleSearchToggle} />
                  <form
                    role="search"
                    id="searchform"
                    action="/search"
                    method="get"
                    className="radius-xl"
                  >
                    <div className="input-group">
                      <input
                        defaultValue=""
                        name="q"
                        type="search"
                        placeholder={LANG.form.search[lang]}
                      />
                      <span className="input-group-btn">
                        <button type="button" className="search-btn">
                          <i className="fa fa-search arrow-animation" />
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    ButtonChangeEN: () => {
      localStorage.setItem("lang", "en");
      dispatch({ type: "en" });
    },
    ButtonChangeVI: () => {
      localStorage.setItem("lang", "vi");
      dispatch({ type: "vi" });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Header);
