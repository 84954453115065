import React from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var LANG = {
  awardText: {
    vi: "Giải thưởng",
    en: "Our Rewards",
  },
  award1: {
    vi: "Các giải thưởng công trình King Crown Infifinity",
    en: "King Crown Infinity Awards",
  },
  award2: {
    vi: "Giải Nhì cuộc thi Ý tưởng Quy hoạch xây dựng vùng huyện Bình Chánh đến năm 2040",
    en: "Second Prize of Competition “Concept of Construction Planning Binh Chanh District to 2040",
  },
  award3: {
    vi: "Giải Nhất cuộc thi thiết kế kiến trúc Khối lớp học - Thí nghiệm Khoa Công nghệ Sinh học QT.B4 (Khối 7 tầng) thuộc trường Đại học Quốc tế",
    en: "First Prize of Architectural Design Competition for the Classroom - Experiment of the De- partment of Biotechnology QT.B4 (7 floors) of the International University",
  },
  award4: {
    vi: "Giải Nhất cuộc thi thiết kế kiến trúc Thư viện Cần Thơ",
    en: "First Prize of Architectural Design Competition for Can Tho Library",
  },
  award5: {
    vi: "Giải Nhất cuộc thi thiết kế kiến trúc Trụ sở làm việc Văn phòng Đoàn Đại biểu Quốc Hội",
    en: "First Prize of Architectural Design Competition for Parliament Delegation’s Office Building",
  },
  award6: {
    vi: "Giải Nhất cuộc thi thiết kế kiến trúc Trụ sở làm việc Sở Tài Nguyên & Môi trường",
    en: "First Prize of Architectural Design Competition for Nature Resources & Environment De- partment’s Office Building",
  },
  award7: {
    vi: "Giải Nhất cuộc thi thiết kế kiến trúc Nhà Văn hóa Lao động tỉnh An Giang",
    en: "First Prize of Architectural Design Competition for An Giang Labour Culture Centre",
  },
  award8: {
    vi: "Giải Nhất cuộc thi thiết kế phương án Quy hoạch chung thành phố Châu Đốc",
    en: "First Prize of Urban Planning Design Competition for Chau Doc City",
  },
  award9: {
    vi: "Giải Nhất cuộc thi thiết kế phương án Quy hoạch chung thành phố Châu Đốc",
    en: "First Prize of Urban Planning Design Competition for Chau Doc City",
  },

  award10: {
    vi: "Giải Nhất cuộc thi thiết kế kiến trúc công trình trụ sở Sở Xây Dựng An Giang",
    en: "First Prize of Architectural Design Competition for An Giang Constrcuction Depart- ment’s Office Building",
  },
  award11: {
    vi: 'Giải Khuyến khích - Giải thưởng Kiến trúc Quốc gia năm 2011 - Quy hoạch chi tiết xâydựng " Khu vườn tổng hợp nuôi trồng thủy sản kỹ thuật cao biển đảo Phú Quốc"',
    en: 'Consolation Prize - National Architecture Award 2011 - Detailed construction planning "Phu Quoc island high-tech aquaculture integrated garden"',
  },
};
var options = {
  loop: false,
  autoplay: true,
  margin: 60,
  nav: true,
  dots: false,
  items: 2,
  responsive: {
    0: {
      items: 1,
    },
    991: {
      items: 2,
    },
  },
};
var imagesAward1 = [
  require("./../../images/adwards/award1.jpg"),
  require("./../../images/adwards/award2.jpg"),
  require("./../../images/adwards/award3.jpg"),
  require("./../../images/adwards/award4.jpg"),
];
var imagesAward2 = [
  require("./../../images/adwards/award5.jpg"),
  require("./../../images/adwards/award28.jpg"),
];
var imagesAward3 = [
  require("./../../images/adwards/award6.png"),
  require("./../../images/adwards/award7.png"),
  require("./../../images/adwards/award8.png"),
  require("./../../images/adwards/award9.jpg"),
];
var imagesAward4 = [
  require("./../../images/adwards/award21.png"),
  require("./../../images/adwards/award22.png"),
  require("./../../images/adwards/award23.png"),
  require("./../../images/adwards/award24.png"),
  require("./../../images/adwards/award25.png"),
  require("./../../images/adwards/award26.png"),
  require("./../../images/adwards/award27.png"),
];
var imagesAward5 = [
  require("./../../images/adwards/award19.png"),
  require("./../../images/adwards/award20.png"),
];
var imagesAward6 = [
  require("./../../images/adwards/award17.png"),
  require("./../../images/adwards/award18.png"),
];
var imagesAdward7 = require("./../../images/adwards/award16.jpg");
var imagesAward8 = [
  require("./../../images/adwards/award13.jpg"),
  require("./../../images/adwards/award14.jpg"),
  require("./../../images/adwards/award15.jpg"),
];
var imagesAdward9 = require("./../../images/adwards/award10.png");
var imagesAdward10 = require("./../../images/adwards/award11.jpg");
var imagesAdward11 = require("./../../images/adwards/award12.png");

class AwardsList extends React.Component {
  render() {
    const lang = this.props.lang;

    return (
      <>
        <div
          id="people"
          className="section-full p-t80 p-b50 mobile-page-padding"
        >
          <div className="container">
            {/* TITLE START */}

            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator">
                  <h3 className="sep-line-one">{LANG.awardText[lang]}</h3>
                </div>
              </div>
            </div>

            <div className="section-content">
              <div className="m-b30">
                <p className="p-award">1. {LANG.award1[lang]}:</p>
                <div className="list-awards">
                  {imagesAward1.map((item, index) => (
                    <div className="item-awards item-awards1" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">2. {LANG.award2[lang]}:</p>
                <div className="list-awards list-awards-one">
                  {imagesAward2.map((item, index) => (
                    <div className="item-awards item-awards2" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">3. {LANG.award3[lang]}:</p>
                <div className="list-awards">
                  <OwlCarousel
                    className="owl-carousel owl-btn-vertical-center"
                    {...options}
                  >
                    {imagesAward3.map((item, index) => (
                      <div className="item" key={index}>
                        <img src={item} alt="" />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">4. {LANG.award4[lang]}:</p>
                <div className="list-awards">
                  <OwlCarousel
                    className="owl-carousel owl-btn-vertical-center"
                    {...options}
                  >
                    {imagesAward4.map((item, index) => (
                      <div className="item" key={index}>
                        <img src={item} alt="" />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>{" "}
              </div>

              <div className="m-b30">
                <p className="p-award">5. {LANG.award5[lang]}:</p>
                <div className="list-awards">
                  <OwlCarousel
                    className="owl-carousel owl-btn-vertical-center"
                    {...options}
                  >
                    {imagesAward5.map((item, index) => (
                      <div className="item" key={index}>
                        <img src={item} alt="" />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">6. {LANG.award6[lang]}:</p>
                <div className="list-awards">
                  <OwlCarousel
                    className="owl-carousel owl-btn-vertical-center"
                    {...options}
                  >
                    {imagesAward6.map((item, index) => (
                      <div className="item" key={index}>
                        <img src={item} alt="" />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">7. {LANG.award7[lang]}:</p>
                <div className="list-awards list-awards-one">
                  <div className="item-awards item-awards2">
                    <img src={imagesAdward7} alt="" />
                  </div>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">8. {LANG.award8[lang]}:</p>
                <div className="list-awards">
                  <OwlCarousel
                    className="owl-carousel owl-btn-vertical-center"
                    {...options}
                  >
                    {imagesAward8.map((item, index) => (
                      <div className="item" key={index}>
                        <img src={item} alt="" />
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">9. {LANG.award9[lang]}:</p>
                <div className="list-awards list-awards-one">
                  <div className="item-awards item-awards2">
                    <img src={imagesAdward9} alt="" />
                  </div>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">10. {LANG.award10[lang]}:</p>
                <div className="list-awards list-awards-one">
                  <div className="item-awards item-awards2">
                    <img src={imagesAdward10} alt="" />
                  </div>
                </div>
              </div>

              <div className="m-b30">
                <p className="p-award">11. {LANG.award11[lang]}:</p>
                <div className="list-awards list-awards-one">
                  <div className="item-awards item-awards2">
                    <img src={imagesAdward11} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(AwardsList);
