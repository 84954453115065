import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import Services1 from "./../Elements/Services1";
import LogoFINE from "./../Elements/LogoFINE";
import Team from "./../Elements/Team";
import ProjectGrid3 from "./ProjectGrid3";
import { connect } from "react-redux";

const LANG = {
  intro1: {
    vi: `<span class="text-bold">S</span>áng tạo`,
    en: `<span class="text-bold">C</span>reative`,
  },
  intro2: {
    vi: `<span class="text-bold">Đ</span>ương đại`,
    en: `<span class="text-bold">C</span>ontemporary`,
  },
  intro3: {
    vi: `<span class="text-bold">U</span>y tín`,
    en: `<span class="text-bold">C</span>redible`,
  },
};
class Home1 extends React.Component {
  render() {
    const lang = this.props.lang;

    return (
      <>
      
        <Header />
        <div className="logo-wrapper">
          <div className="group-center">
            <LogoFINE />
            <p className={`intro-load intro-text temp-${lang}`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: LANG.intro1[lang],
                }}
              ></span>
              <span>-</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: LANG.intro2[lang],
                }}
              ></span>
              <span>-</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: LANG.intro3[lang],
                }}
              ></span>
            </p>
          </div>
        </div>
        <div className="page-content">
          <Services1 />
          <Team />
        </div>
        <Footer />
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};
export default connect(mapStatetoProps, mapDispatchtoProps)(Home1);
