const initialState = {
  lang: "en",
  value: "#all",
  project_list: [],
  loading: true,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  if (action.type === "en") {
    newState.lang = "en";
  }
  if (action.type === "vi") {
    newState.lang = "vi";
  }
  if (action.type === "project") {
    newState.value = action.value;
  }
  if (action.type === "project_list") {
    newState.project_list = action.value;
    newState.loading = false;
  }
  if (action.type === "loading") {
    console.log('loading ', action.value)
    newState.loading = action.value;
  }
  return newState;
};

export default reducer;
