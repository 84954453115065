import React from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { partnerNational, partnerInternational } from "../../common/lang";

const imagesNational = [
  require("./../../images/partner/partner1.png"),
  require("./../../images/partner/partner8.png"),
  require("./../../images/partner/partner7.png"),
  require("./../../images/partner/partner6.png"),
  require("./../../images/partner/partner5.jpg"),
  require("./../../images/partner/partner_update1.jpg"),
  require("./../../images/partner/partner_update2.jpg"),
  require("./../../images/partner/partner4.png"),
  require("./../../images/partner/partner3.png"),
  require("./../../images/partner/partner2.png"),
];
const imagesInternational = [
  require("./../../images/partner/partner16.jpg"),
  require("./../../images/partner/partner15.png"),
  require("./../../images/partner/partner14.png"),
  require("./../../images/partner/partner13.png"),
  require("./../../images/partner/partner12.png"),
  require("./../../images/partner/partner11.jpg"),
  require("./../../images/partner/partner10.jpg"),
  require("./../../images/partner/partner9.png"),
];
const arr1 = [
  require("./../../images/partner/partner1.png"),
  require("./../../images/partner/partner8.png"),
  require("./../../images/partner/partner7.png"),
  require("./../../images/partner/partner6.png"),
];
const arr2 = [
  require("./../../images/partner/partner5.jpg"),
  require("./../../images/partner/partner_update1.jpg"),
  require("./../../images/partner/partner_update2.jpg"),
  require("./../../images/partner/partner4.png"),
  require("./../../images/partner/partner3.png"),
  require("./../../images/partner/partner2.png"),
];
const arr3 = [
  require("./../../images/partner/partner16.jpg"),
  require("./../../images/partner/partner15.png"),
  require("./../../images/partner/partner14.png"),
  require("./../../images/partner/partner13.png"),
  require("./../../images/partner/partner12.png"),
  require("./../../images/partner/partner11.jpg"),
];
const arr4 = [
  require("./../../images/partner/partner10.jpg"),
  require("./../../images/partner/partner9.png"),
];

const options = {
  loop: true,
  autoplay: true,
  margin: 40,
  nav: false,
  dots: true,
  items: 5,
};

const LANG = {
  en: {
    titleNational: "Strategic Partners",
    titleInternational: "International Partners",
    thead: {
      no: "No",
      name: "Business name",
      sub: "Field of activity",
    },
  },
  vi: {
    titleNational: "Đối tác chiến lược",
    titleInternational: "Đối tác nước ngoài",
    thead: {
      no: "Stt",
      name: "Tên doanh nghiệp",
      sub: "Lĩnh vực hoạt động",
    },
  },
};

class Partners extends React.Component {
  render() {
    const lang = this.props.lang;
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b80 cus-table cus-carousel">
          <div className="container">
            <div className="section-content">
              {/* block 1 */}
              <div>
                <div className="section-head">
                  <div className="sx-separator-outer separator-left">
                    <div className="sx-separator">
                      <h3 className="sep-line-one">
                        {LANG[lang].titleNational}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {arr1.map((item, index) => (
                    <div
                      className="item-partner col-lg-3 col-md-4 col-sm-6 m-b30"
                      key={index}
                    >
                      <img src={item} alt="" />
                    </div>
                  ))}
                </div>
                <div className="row">
                  {arr2.map((item, index) => (
                    <div
                      className="item-partner col-lg-4 col-md-4 col-sm-6 m-b30"
                      key={index}
                    >
                      <img
                        src={item}
                        className={`cus-img-partner${index}`}
                        alt=""
                      />
                    </div>
                  ))}
                </div>

                {/* COMMENT SLIDER AND TABLE */}
                {/* <OwlCarousel className="owl-carousel owl-btn-vertical-center" {...options}>
                  {imagesNational.map((item, index) => (
                    <div className="item" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </OwlCarousel>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">{LANG[lang].thead.no}</th>
                      <th scope="col">{LANG[lang].thead.name}</th>
                      <th scope="col">{LANG[lang].thead.sub}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnerNational[lang].map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td style={{ width: "38%" }}>{item.name}</td>
                        <td>{item.sub}</td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
              {/* block 2 */}
              <div style={{ marginTop: "72px" }}>
                <div className="rowflex-partner">
                  {imagesInternational.map((item, index) => (
                    <div className="item-partner" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </div>

                {/* COMMENT SLIDER AND TABLE */}
                {/* <OwlCarousel className="owl-carousel owl-btn-vertical-center" {...options}>
                  {imagesInternational.map((item, index) => (
                    <div className="item" key={index}>
                      <img src={item} alt="" />
                    </div>
                  ))}
                </OwlCarousel>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">{LANG[lang].thead.no}</th>
                      <th scope="col">{LANG[lang].thead.name}</th>
                      <th scope="col">{LANG[lang].thead.sub}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnerInternational[lang].map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td style={{ width: "38%" }}>{item.name}</td>
                        <td>{item.sub}</td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Partners);
