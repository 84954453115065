export var projectList = [
  {
    name: {
      vi: "Tất cả",
      en: "All",
    },
    id: "#all",
    text: "All",
  },
  {
    name: {
      vi: "Quy hoạch đô thị",
      en: "Urban Planing",
    },
    id: "#urban",
    text: "Urban Planing",
  },
  {
    name: {
      vi: "Chung cư cao tầng",
      en: "High Rise Apartment",
    },
    id: "#highrise",
    text: "High Rise Apartment",
  },
  
  {
    name: {
      vi: "Thương mại",
      en: "Commercial",
    },
    id: "#commercial",
    text: "Commercial",
  },
  {
    name: {
      vi: "Giáo dục",
      en: "Educational",
    },
    id: "#educational",
    text: "Educational",
  },
  {
    name: {
      vi: "Văn hóa",
      en: "Culture",
    },
    id: "#culture",
    text: "Culture",
  },
  {
    name: {
      vi: "Du lịch",
      en: "Hospitality",
    },
    id: "#hospitality",
    text: "Hospitality",
  },
  {
    name: {
      vi: "Công cộng",
      en: "Public Building",
    },
    id: "#public",
    text: "Public Building",
  },
  {
    name: {
      vi: "Văn phòng",
      en: "Office Building",
    },
    id: "#office",
    text: "Office Building",
  },
  {
    name: {
      vi: "Công nghiệp",
      en: "Industrial",
    },
    id: "#industrial",
    text: "Industrial",
  },
  {
    name: {
      vi: "Nhà ở",
      en: "Residental",
    },
    id: "#housing",
    text: "Housing",
  },
  {
    name: {
      vi: "Nội thất",
      en: "Interior",
    },
    id: "#interior",
    text: "Interior",
  },
];

export var partnerNational = {
  vi: [
    {
      name: "Công ty TNHH MTV Kỹ thuật và Công nghệ xây dựng Acons (ACONS)",
      sub: "Tư vấn thiết kế chuyên nghiệp về nền móng và Kết cấu – Hạ tầng",
    },
    {
      name: "Công Ty TNHH Tư Vấn – Thiết Kế - Xây Dựng Khánh Phát. (KPCONS)",
      sub: "Nhà thầu thiết kế chuyên nghiệp kết cấu, MEP, Tư vấn Quản lý dự án, Tư vấn Giám sát",
    },
    {
      name: "Công ty Cổ Phần Tư vấn Kiến Trúc Xây Dựng Tp.Hồ Chí Minh",
      sub: "Tư vấn kiến trúc và xây dựng",
    },
    {
      name: "Công ty Cổ Phần Tư Vấn Xây Dựng Tổng hợp Nagecco",
      sub: "",
    },
    {
      name: "Viện Quy Hoạch Xây Dựng Tp.Hồ Chí Minh",
      sub: "",
    },
    {
      name: "Tập đoàn xây dựng Hoà Bình - HBCG",
      sub: "",
    },
    {
      name: "Công ty TNHH Tư Vấn Thiết kế Hoà Bình - HBA",
      sub: "",
    },
    {
      name: "Công ty TNHH Hoàn cầu Việt Nam",
      sub: "",
    },
    {
      name: "Công ty Cổ phần GAMMA",
      sub: "Giải pháp nhà thông minh - Smart home",
    },
    {
      name: "Công ty TNHH Top Fire Việt Nam",
      sub: "Thiết kế và thi công hệ thống PCCC – CCTV – Chống sét/ Fire Protection System - CCTV - Anti-lightning Design& Construction",
    },
    {
      name: "Công ty Cổ Phần Công nghệ thiết bị dịch vụ và môi trường Ánh Thuỷ",
      sub: "Thiết kế, thi công và quản lý dự án liên quan đến môi trường/ Design, construction and management of projects related to the environment",
    },
    {
      name: "Công ty TNHH Sản xuất Toàn Cầu LIXIL Việt Nam",
      sub: "Cung cấp thiết bị vệ sinh, sản phẩm nhôm cao cấp/ Sanitary Ware and Premium Aluminum Products Suppliers",
    },
    {
      name: "Công ty Cổ phần Đay Sài Gòn – SJJC",
      sub: "",
    },
    {
      name: "Công ty Cổ phần Đầu tư & Dịch vụ Helios",
      sub: "",
    },
    {
      name: "Tập đoàn Bamboo Capital – BCG",
      sub: "Xây dựng và Bất động sản/ Xây dựng công nghiệp nặng và dân dụng/ Xây dựng công nghiệp nặng và dân dụng khác",
    },
    {
      name: "Công ty Cổ phần hệ thống an ninh Khai Phát KPS",
      sub: "Hệ thống an ninh, âm thanh, bãi xe thông minh, toà nhà thông minh và phòng cháy chữa cháy",
    },
    {
      name: "Công ty TNHH Hà Thanh Sài Gòn (HTSG)",
      sub: "Thiết kế hệ thống chiếu sáng nội – ngoại thất / Interior-Exterior Lighting Design",
    },
    {
      name: "Công ty cổ phần đầu tư L.A.D",
      sub: "Tư vấn, thiết kế và thi công cảnh quan chuyên nghiệp/ Landscape Consultancy Design &  Construction",
    },
    {
      name: "Công ty TNHH Hoda Metal Industries Việt Nam (Hondalex)",
      sub: "Tư vấn, thiết kế và thi công nhôm kính/Consulting, Design and Construction for Aluminium Glass Products",
    },
  ],
  en: [
    {
      name: "ACONS ENGINEERING &TECHNOLOGY CONSTRUCTION COMPANY LIMITED",
      sub: "Foundation and Structure-Infrastructure Consultancy Design",
    },
    {
      name: "KHANH PHAT CONSTRUCTION - DESIGNING - CONSULTANT JOINT STOCK COMPANY (KPCONS)",
      sub: "Structure, MEP Consultancy Design, Project Management Consultancy, Supervision Consultancy",
    },
    {
      name: "ARCHITECTURE AND CONSTRUCTION CONSULTANTS JOINT STOCK COMPANY (ACCCO)",
      sub: "Architecture & Construction Consultants",
    },
    {
      name: "NATIONAL GENERAL CONSTRUCTION CONSULTING JOINT STOCK COMPANY",
      sub: "",
    },
    {
      name: "Ho Chi Minh City Urban Planning Institute",
      sub: "",
    },
    {
      name: "Hoa Binh Construction Group - HBCG",
      sub: "",
    },
    {
      name: "Hoa Binh Architecture - HBA",
      sub: "",
    },
    {
      name: "VIETNAM GLOBAL PARTNER ENTERPRISES LIMITED",
      sub: "",
    },
    {
      name: "GAMMA JOINT STOCK COMPANY",
      sub: "Smart home solutions",
    },
    {
      name: "TOP FIRE VIETNAM COMPANY LIMITED",
      sub: "Fire Protection System - CCTV - Anti-lightning Design& Construction",
    },
    {
      name: "ANH THUY ENVIRONMENT AND SERVICES EQUIPMENT TECHNOLOGY JOINT STOCK COMPANY",
      sub: "Design, construction and management of projects related to the environment",
    },
    {
      name: "LIXIL GLOBAL MANUFACTURING VIETNAM COMPANY LIMITED",
      sub: "Sanitary Ware and Premium Aluminum Products Suppliers",
    },
    {
      name: "Đay Sai Gon JSC – SJJC",
      sub: "",
    },
    {
      name: "HELIOS SERVICE AND INVESTMENT JOINT STOCK COMPANY",
      sub: "",
    },
    {
      name: "Bamboo Capital Group Joint Stock Company - BCG",
      sub: "Construction and Real Estate/ Heavy and Civil Engineering Construction/ Other Heavy and Civil Engineering Construction",
    },
    {
      name: "KHAI PHAT SECURITY SYSTEM CORPORATION",
      sub: "Smart parking & Smart Control",
    },
    {
      name: "HA THANH SAI GON INVESTMENT TRADING SERVICE COMPANY LIMITED (HTSG)",
      sub: "Interior-Exterior Lighting Design",
    },
    {
      name: "L.A.D INVESTMENT JOINT STOCK COMPANY",
      sub: "Landscape Consultancy Design &  Construction",
    },
    {
      name: "HONDA METAL INDUSTRIES VIETNAM,LTD (Hondalex)",
      sub: "Consulting, Design and Construction for Aluminium Glass Products",
    },
  ],
};

export var partnerInternational = {
  vi: [
    {
      name: "Asia United Architecture Association (AUA Asociation)",
      sub: "Hiệp hội Kiến trúc Châu Á",
    },
    {
      name: "Syska Hennessy Group – USA",
      sub: "Smart Building",
    },
    {
      name: "KONE Corporation - Japan",
      sub: "Cung cấp thang máy , thang cuốn và cửa tự động",
    },
    {
      name: "Fluidra – USA",
      sub: "Trải nghiệm hồ bơi & sức khỏe hoàn hảo",
    },
    {
      name: "SAHVIA – India",
      sub: "Thiết Kế Mặt Tiền & Thiết Kế Chiếu Sáng Nghệ Thuật",
    },
  ],
  en: [
    {
      name: "Asia United Architecture Association (AUA Asociation)",
      sub: "",
    },
    {
      name: "Syska Hennessy Group – USA",
      sub: "Smart Building",
    },
    {
      name: "KONE Corporation - Japan",
      sub: "Elevator, Escalator and Automatic Door Supplier",
    },
    {
      name: "Fluidra – USA",
      sub: "Perfect Pool & Wellness experience",
    },
    {
      name: "SAHVIA – India",
      sub: "Facade Design & Art Lighting Design",
    },
  ],
};
