
export const ApiFilter = {
  filters: [
    { label: "Urban Planing", filter: ".urban", id: "urban" },
    { label: "High Rise Apartment", filter: ".highrise", id: "highrise" },
    { label: "Commercial", filter: ".commercial", id: "commercial" },
    { label: "Educational", filter: ".educational", id: "educational" },
    { label: "Culture", filter: ".culture", id: "culture" },
    { label: "Hospitality", filter: ".hospitality", id: "hospitality" },
    { label: "Public Building", filter: ".public", id: "public" },
    { label: "Office Building", filter: ".office", id: "office" },
    { label: "Industrial", filter: ".industrial", id: "industrial" },
    { label: "Housing", filter: ".housing" , id: "housing"},
    { label: "Interior", filter: ".interior", id: "interior" },
  ],
  projects: [],
  mapCache: {},
};
