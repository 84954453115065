import React from "react";
import OwlCarousel from "react-owl-carousel";
import { connect } from "react-redux";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const LANG = {
  similar: {
    en: "Related Projects",
    vi: "Dự án tương tự",
  },
};

class Projects6 extends React.Component {
  constructor(props) {
    super(props);
    console.log("project : ", this.props.similars);
  }

  render() {
    const lang = this.props.lang;
    const similars = this.props.similars;
    const options = {
      loop: false,
      autoplay: false,
      center: false,
      items: 3,
      margin: 20,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          margin: 15,
        },
        640: {
          items: 2,
          margin: 15,
        },
        768: {
          items: 2,
          margin: 15,
        },
        991: {
          items: 3,
          margin: 15,
        },
        1200: {
          items: 3,
        },
      },
    };
    return (
      <>
        <div
          key={lang}
          className="section-full p-tb80 bg-gray inner-page-padding"
        >
          <div className="container">
            <div className="section-content">
              {/* TITLE START */}

              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <div className="sx-separator bg-moving bg-repeat-x v2-title-thin">
                    <h3 className="sep-line-one">{LANG.similar[lang]}</h3>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              <div key={similars.length} className="work-carousel-outer">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  {similars.map((item, index) => (
                    <a key={index} href={`/project/${item.id}`}>
                      <div className="item">
                        <div className="project-mas m-a30">
                          <div className="image-effect-one cus-img-slicer">
                            {/* <img src={item.image} alt="" /> */}
                            <div
                              className="cus-bg-slider"
                              style={{ background: `url(${item.thumbnail})` }}
                            ></div>
                            <div className="figcaption">
                              <a className="mfp-link" href={item.thumbnail}>
                                <i className="fa fa-arrows-alt" />
                              </a>
                            </div>
                          </div>
                          <div className="project-info p-t20">
                            <h4 className="sx-tilte  m-t0">
                              <a>{item[`name_${lang}`]}</a>
                            </h4>
                            <a>
                              <i className="link-plus bg-primary" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    lang: state.lang,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {};
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Projects6);
